/* eslint-disable import/no-extraneous-dependencies */
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import InputAdornment from '@mui/material/InputAdornment';
// hooks
import { Grid, MenuItem, Skeleton, useMediaQuery, useTheme } from '@mui/material';

// import { useBoolean } from 'src/hooks/use-boolean';

// components
// import Iconify from 'src/components/iconify';
import { useSnackbar } from 'notistack';
import FormProvider, { RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import { WebServices } from 'src/services/requests';
import { fData, fPhoneNumber } from 'src/utils/format-number';
import RHFIpnutMask from 'src/components/hook-form/rhf-input-mask';
// import RHFSwitch from 'src/components/hook-form/rhf-switch';
// import { useAuthContext } from 'src/auth/hooks';
import { useRenderData } from '../context/context';

// ----------------------------------------------------------------------

export default function JwtRegisterView() {
  const [isNewImageUploaded, setIsNewImageUploaded] = useState(false);

  const { resDataAll } = useRenderData();

  // const { user } = useAuthContext();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { enqueueSnackbar } = useSnackbar();

  // console.log(resDataAll);

  const [resDataUserTypes, setResDataUserTypes] = useState(null);

  async function fetchAllData() {
    const { data } = await WebServices.getAllUserTypes();
    setResDataUserTypes(data?.data);
  }

  useEffect(() => {
    fetchAllData();
  }, []);

  // const password = useBoolean();

  const RegisterSchema = Yup.object().shape({
    NameSurname: Yup.string().required('İsim soyisim zorunlu'),
    // Password: Yup.string().required('Password  zorunlu'),
    Email: Yup.string().required('E-Posta zorunlu').email('Mail adres formatı zorunlu'),
    PhoneNumber: Yup.string()
      .required('Telefon Zorunlu')
      .test('no-leading-zero', 'Telefon numarası "0" ile başlayamaz', (value) => {
        if (value) {
          return !value.startsWith('(0');
        }
        return true;
      }),
    UserTypeId: Yup.string().required('Sektördeki Pozisyon zorunlu'),
    // ImageFile: Yup.mixed().test(
    //   'fileFormat',
    //   'İzin Verilen Formatlar .PNG, .JPG, .JPEG, .SVG',
    //   (value) => {
    //     if (!isNewImageUploaded) return true; // Sadece isNewImageUploaded true ise testi uygula
    //     if (!value) return true; // Allow null or undefined
    //     const allowedFormats = ['.png', '.jpg', '.jpeg', '.svg'];
    //     const fileExtension = value.name.split('.').pop().toLowerCase();
    //     return allowedFormats.includes(`.${fileExtension}`);
    //   }
    // ),
    // .required('Resim Zorunlu'),
  });

  const defaultValues = {
    NameSurname: '',
    Email: '',
    PhoneNumber: '',
    UserTypeId: '',
    ImageFile: null,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    // reset,
    watch,
    // control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    setValue('ImageFile', resDataAll?.imageUrl || null);
    setValue('NameSurname', resDataAll?.nameSurname || null);
    setValue('Email', resDataAll?.email || null);
    setValue('PhoneNumber', resDataAll?.phoneNumber || null);
    setValue('UserTypeId', resDataAll?.userTypeId || null);
  }, [resDataAll, setValue]);

  // const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    const isForm = true;

    // console.log(fPhoneNumber(values.PhoneNumber));

    const sendData = {
      ...data,
      ImageFile: isNewImageUploaded ? values.ImageFile : null,
      PhoneNumber: fPhoneNumber(values.PhoneNumber),
    };

    // console.log('sendData ');

    const response = await WebServices.updateUser(sendData, isForm);
    // console.log('create category = ', response);
    if (response.success) {
      enqueueSnackbar('İşlem Başarılı');
      // console.log("dönen başarı register : ",response);
      // reset();
    } else {
      enqueueSnackbar(
        response?.response?.response?.data?.detailMessage || 'Beklenmeyen Bir Hata Oluştu',
        {
          variant: 'error',
        }
      );
      //  console.log("dönen error register msg : ",response.response);
    }
  });

  const handleDropImage = useCallback(
    (acceptedFiles, setIsNewUpload, valueName) => {
      setIsNewUpload(true);
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue(valueName, newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const values = watch();

  const ButtonSection = (
    <LoadingButton
      fullWidth
      sx={{
        background: '#F8E71C',
        width: isMobile ? 1 : '30%',
        color: '#000',
        '&:hover': {
          background: '#F8E71C', // Keep the same background color on hover
        },
      }}
      size="large"
      type="submit"
      variant="text"
      loading={isSubmitting}
    >
      Güncelle
    </LoadingButton>
  );

  const renderForm = (
    <Grid container spacing={isMobile ? 1 : 5} pt={0} mt={0}>
      <Grid item xs={12} md={8}>
        <Stack spacing={{ xs: 2, md: 2 }} mt={0}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium max-md:text-sm" variant="subtitle2">
                Ad Soyad
              </Typography>
              {/* <Typography className="text-gray-500 text-sm" variant="caption">
                Mevcut : Erkan Giriş
              </Typography> */}
            </Grid>

            <Grid item xs={12} md={8}>
              <RHFTextField name="NameSurname" placeholder="Adınız Soyadınız" />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium max-md:text-sm" variant="subtitle2">
                E-Posta Adresiniz
              </Typography>
              {/* <Typography className="text-gray-500 text-sm" variant="caption">
                Mevcut : erkan@gmail.com
              </Typography> */}
            </Grid>

            <Grid item xs={12} md={8}>
              <RHFTextField name="Email" placeholder="E-mail Adresiniz" />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium max-md:text-sm" variant="subtitle2">
                Telefon Numaranız
              </Typography>
              {/* <Typography className="text-gray-500 text-sm" variant="caption">
                Mevcut : 13123213123
              </Typography> */}
            </Grid>

            <Grid item xs={12} md={8}>
              {/* <RHFTextField name="PhoneNumber" /> */}
              <RHFIpnutMask
                name="PhoneNumber"
                mask="(999) 999-9999"
                placeholder="Telefon Numaranız"
                // helperText="Enter your phone number"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium max-md:text-sm" variant="subtitle2">
                Sektördeki Pozisyon
              </Typography>
              {/* <Typography className="text-gray-500 text-sm" variant="caption">
                Mevcut : Admin
              </Typography> */}
            </Grid>

            <Grid item xs={12} md={8}>
              {resDataUserTypes ? (
                <RHFSelect
                  name="UserTypeId"
                  placeholder="Sektördeki Pozisyon Seçiniz"
                  InputLabelProps={{ shrink: true }}
                >
                  {resDataUserTypes?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.userType}
                    </MenuItem>
                  ))}
                </RHFSelect>
              ) : (
                <Skeleton height={50} width="100%" />
              )}
            </Grid>
          </Grid>

          {!isMobile && (
            <Grid container spacing={1}>
              <Grid item xs={4} />

              <Grid item xs={12} md={8}>
                {ButtonSection}
              </Grid>
            </Grid>
          )}
        </Stack>
      </Grid>

      <Grid item xs={12} md={4} my={isMobile && 1}>
        <Stack
          // className="border border-solid border-gray-200 rounded-md w-80 p-10"
          sx={{
            border: '1px solid rgba(145, 158, 171, 0.2)',
            borderRadius: '8px',
            p: 5,
          }}
          alignItems="center"
          spacing={1}
        >
          <RHFUploadAvatar
            isRounded
            sx={{
              width: '150px',
              height: '150px',
            }}
            name="ImageFile"
            maxSize={1048576}
            onDrop={(acceptedFiles) =>
              handleDropImage(acceptedFiles, setIsNewImageUploaded, 'ImageFile')
            }
            helperText={
              <Typography
                variant="caption"
                sx={{
                  mt: 3,
                  mx: 'auto',
                  display: 'block',
                  textAlign: 'center',
                  color: 'text.disabled',
                  maxWidth: '215px',
                }}
              >
                Şu Formatlarda Yükleyiniz: *.PNG, *.JPG, *.JPEG, *.SVG
                <br /> En fazla dosya boyutu: {fData(1048576)}
              </Typography>
            }
          />
          {/* <RHFSwitch
            name="isPublic"
            labelPlacement="start"
            label="Fotoğrafımı Göster"
            sx={{ mt: 5 }}
          /> */}
        </Stack>
      </Grid>

      {isMobile && (
        <Grid item xs={12}>
          {ButtonSection}
        </Grid>
      )}
    </Grid>
  );

  // console.log(user);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2}>
        {/* <Typography variant={isMobile ? 'h5' : 'h3'}>{user?.NameSurname}</Typography> */}
        {/* <Typography variant={isMobile ? 'h5' : 'h3'}>Profil</Typography> */}
        <div className="flex flex-col gap-2 max-md:gap-0">
          <h1 className="text-3xl font-semibold max-md:text-lg">Profil</h1>
          <p className="opacity-50 text-black max-md:text-xs">
            Profil bilgilerinizi bu sayfadan güncelleyebilirsiniz.
          </p>
        </div>
        {renderForm}
      </Stack>
    </FormProvider>
  );
}
