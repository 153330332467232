/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { Avatar } from '@mui/material';
import React from 'react';
import { fNumber } from 'src/utils/format-number';

export default function LeaderBoardList({ resData }) {
  // const theme = useTheme();

  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // console.log(isMobile);

  // const showImagesCount = isMobile ? 3 : 5;

  return (
    <div className="flex flex-col  max-lg:flex max-lg:flex-wrap max-lg:flex-row max-lg:gap-5 max-md:flex-col max-lg:items-start max-md:gap-4">
      {resData.map((movieItem, movieIndex) => (
        <div
          key={movieIndex}
          className={`w-full flex items-center justify-between ${
            movieItem?.isOwnUser ? 'border-2' : 'border'
          } border-solid mb-3 max-md:mb-0 max-lg:w-[calc(50%-10px)] max-md:w-full border-[${
            movieItem?.isOwnUser ? '#0071E3' : '#DFDFDF'
          }] rounded-lg shadow-md p-5 max-lg:flex-col max-lg:gap-5 max-md:p-4 `}
        >
          <div className="flex gap-5 items-center min-w-[500px] max-xl:max-w-[350px] max-xl:min-w-[350px] max-lg:w-full max-lg:max-w-full max-lg:min-w-full max-lg:border-solid max-lg:border-b max-lg:border-gray-300 max-lg:pb-4">
            <div
              className={`text-[${
                movieItem?.isOwnUser && '#0071E3'
              }] text-4xl max-w-[50px] min-w-[50px]  max-lg:max-w-[30px] max-lg:min-w-[30px] font-bold max-lg:text-2xl`}
            >
              {movieItem?.rank}
            </div>
            <div className="relative">
              <Avatar
                src={movieItem?.profilePhotos}
                className="rounded-full w-20 h-20 max-lg:w-16 max-lg:h-16"
                alt={movieItem?.isOwnUser ? 'Siz' : movieItem?.userNameSurname}
              />
              {movieIndex === 0 && (
                <Avatar
                  className="absolute -top-5 w-6 h-6 left-7 max-lg:left-5"
                  src="/assets/extra/king.svg"
                />
              )}
            </div>
            <div>
              <h2
                className={`text-lg text-${
                  movieItem.isOwnUser ? '[#0071E3]' : 'black'
                } font-semibold max-lg:text-md`}
              >
                {movieItem?.isOwnUser ? 'Siz' : movieItem?.userNameSurname}
              </h2>
              <p className="text-gray-500 font-medium text-sm max-lg:text-xs">
                {movieItem?.userType}
              </p>
            </div>
          </div>
          {/* <div className="flex gap-10 max-lg:w-full max-lg:items-start max-lg:justify-between max-xl:max-w-[325px] max-xl:min-w-[325px] max-lg:max-w-full max-lg:min-w-full items-center  max-lg:gap-5 max-lg:border-solid max-lg:border-b max-lg:border-gray-300 max-lg:pb-4">
            <div className="flex flex-col items-end max-lg:items-start">
              <h3 className="opacity-50 text-sm ">Tahmin Sayısı</h3>
              <div
                className={`text-2xl text-${
                  movieItem.isOwnUser && '[#0071E3]'
                } font-bold max-lg:text-lg`}
              >
                {movieItem?.totalEstimatesCount}
              </div>
            </div>

            <div className="flex min-w-[300px] max-xl:min-w-fit">
              <div className="isolate flex -space-x-3 overflow-hidden">
                {movieItem?.estimatesMovies?.slice(0, showImagesCount).map((imgItem, i) => (
                  <Avatar
                    className="w-12 h-12 border-4 border-solid border-white bg-white"
                    key={i}
                    src={imgItem}
                    width={46}
                    height={46}
                    alt="person"
                  />
                ))}
                {movieItem?.totalEstimatesCount - showImagesCount > 0 && (
                  <div
                    className="relative border-2 border-solid border-gray-100  h-12 w-12 rounded-full bg-gray-400 text-white text-center flex justify-center items-center text-xs font-semibold"
                    width={46}
                    height={46}
                  >
                    +{movieItem?.totalEstimatesCount - showImagesCount}
                  </div>
                )}
              </div>
            </div>
          </div> */}

          <div
            className={` flex gap-9 justify-end min-w-[290px] max-lg:w-full max-lg:justify-between max-xl:min-w-fit `}
          >
            <div className="flex flex-col items-end max-lg:items-start">
              <p className="opacity-50 text-sm">Genel Başarı Yüzdesi</p>
              <div
                className={`text-2xl font-bold max-lg:text-lg text-${
                  movieItem?.isOwnUser && '[#0071E3]'
                }`}
              >
                %{movieItem?.successRate}
              </div>
            </div>
            <div className="flex flex-col items-end max-lg:items-start">
              <p className="text-gray-500 text-sm">Puan</p>
              <div
                className={`text-2xl font-bold max-lg:text-lg text-${
                  movieItem?.isOwnUser && '[#0071E3]'
                }`}
              >
                {fNumber(movieItem?.totalScore)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
