import { Helmet } from 'react-helmet-async';
import { RenderDataProvider } from 'src/sections/completed-movies/context/context';
import CompletedWiew from 'src/sections/completed-movies/view/completedview';

// import View from 'src/sections/home copy/view/usersview';

// sections

// ----------------------------------------------------------------------

export default function Page() {
  return (
    <>
      <Helmet>
        <title>Filmler</title>
      </Helmet>

      <RenderDataProvider>
        <CompletedWiew />
      </RenderDataProvider>
    </>
  );
}
