/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
// @mui
import Container from '@mui/material/Container';
// components
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSettingsContext } from 'src/components/settings';
import { WebServices } from 'src/services/requests';
import EmptyData from 'src/components/empty/emptyData';
import ErrorSection from 'src/components/error/error';
import LoadingSection from 'src/components/loading-screen/loading-section';
import LeaderBoardList from '../list/list';

import { useRenderData } from '../context/context';

// ----------------------------------------------------------------------

export default function LeaderBordView() {
  const settings = useSettingsContext();
  const { resDataAll, setResDataAll, fetchAllData } = useRenderData();
  const [resDropDown, setResDropDown] = useState(null);
  const [selectedMovieId, setSelectedMovieId] = useState('');

  async function fetchDropDownData() {
    const { data } = await WebServices.getAllLeadersBoardDropDown();
    setResDropDown(data?.data);
  }

  useEffect(() => {
    fetchDropDownData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setResDataAll(null);
    fetchAllData({ MovieId: selectedMovieId === '' ? null : selectedMovieId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMovieId]);

  // console.log('resDropDown : ', resDropDown);
  // console.log('resDataAll : ', resDataAll);

  // console.log('leaderdBoards  :', resDataAll?.leaderBoards);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <div className="container mx-auto">
        <div className="flex flex-col gap-2 max-md:mb-5 max-md:gap-0">
          <h1 className="text-3xl max-md:text-lg font-semibold">Liderlik Tablosu</h1>
          <p className="opacity-50 text-black max-md:text-xs">
            Liderlik tablosunu bu kısımda görebilirsiniz.
          </p>
        </div>

        <div className="flex justify-between  items-center max-md:w-full">
          <div className="max-md:hidden" />

          <div className="flex flex-col gap-5 items-start mb-10 max-md:mb-5  max-md:w-full">
            <div className="font-semibold max-md:leading-none ">Filme Göre Liderlik Tablosu</div>
            <div className="flex gap-3  w-full items-center  rounded-md">
              <Autocomplete
                fullWidth
                sx={{ width: '100%', minWidth: '300px' }}
                options={resDropDown || []}
                getOptionLabel={(option) => option.movieName}
                onChange={(event, value) => {
                  // setResDropDown(value ? value.id : null);
                  setSelectedMovieId(value ? value.id : null);
                  // console.log(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Film Seçiniz"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        // eslint-disable-next-line react/jsx-fragments
                        <React.Fragment>
                          {!resDropDown ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>

        {resDataAll && resDataAll?.leaderBoards.length === 0 ? (
          <EmptyData />
        ) : resDataAll ? (
          <LeaderBoardList resData={resDataAll?.leaderBoards} />
        ) : resDataAll === null ? (
          <LoadingSection />
        ) : (
          <ErrorSection
            errorTexts={{
              mainMsg: 'Liderlik Tablosu Yüklenemedi',
            }}
          />
        )}
      </div>
    </Container>
  );
}
