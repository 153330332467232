// import { lazy, Suspense } from 'react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// const IndexPage = lazy(() => import('src/pages/dashboard/home'));
// const GamesPage = lazy(() => import('src/pages/dashboard/my-forecases'));
// const PlayersPage = lazy(() => import('src/pages/dashboard/leaderboard'));
// const ProfilePage = lazy(() => import('src/pages/dashboard/my-profile'));
// const ChangePassword = lazy(() => import('src/pages/dashboard/change-password'));
// const UsersPage = lazy(() => import('src/pages/dashboard/users'));
// const CompletedMovies = lazy(() => import('src/pages/dashboard/completed-movies'));

import IndexPage from 'src/pages/dashboard/home';
import GamesPage from 'src/pages/dashboard/my-forecases';
import PlayersPage from 'src/pages/dashboard/leaderboard';
import ProfilePage from 'src/pages/dashboard/my-profile';
import ChangePassword from 'src/pages/dashboard/change-password';
import UsersPage from 'src/pages/dashboard/users';
import CompletedMovies from 'src/pages/dashboard/completed-movies';
import ForecastInformationPage from 'src/pages/dashboard/forecast-information';
import ManageFilmsPage from 'src/pages/dashboard/manage-films';

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'my-forecases', element: <GamesPage /> },
      { path: 'forecast-information', element: <ForecastInformationPage /> },
      { path: 'leaderboard', element: <PlayersPage /> },
      { path: 'profile', element: <ProfilePage /> },
      { path: 'change-password', element: <ChangePassword /> },
      {
        path: 'manage-movies',
        element: (
          <RoleBasedGuard hasContent roles={['True']}>
            <ManageFilmsPage />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'users',
        element: (
          <RoleBasedGuard hasContent roles={['True']}>
            <UsersPage />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'completed-movies',
        element: (
          <RoleBasedGuard hasContent roles={['True']}>
            <CompletedMovies />
          </RoleBasedGuard>
        ),
      },

      // {
      //   path: 'group',
      //   children: [
      //     { element: <PageFour />, index: true },
      //     { path: 'five', element: <PageFive /> },
      //     { path: 'six', element: <PageSix /> },
      //   ],
      // },
    ],
  },
];
