import { format, getTime, formatDistanceToNow } from 'date-fns';
import { tr } from 'date-fns/locale';

// Türkçe dil ayarları
const locale = { locale: tr };

export function fDate(date, newFormat) {
  const fm = newFormat || 'd MMMM y';

  return date ? format(new Date(date), fm, locale) : '';
}

export function fDateTime(date, newFormat) {
  // const fm = newFormat || 'dd MMM yyyy p';
  const fm = newFormat || 'd MMMM y';


  return date ? format(new Date(date), fm, locale) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: tr,
      })
    : '';
}
