/* eslint-disable import/no-extraneous-dependencies */
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import InputAdornment from '@mui/material/InputAdornment';
// hooks
import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from '@mui/material';

// import { useBoolean } from 'src/hooks/use-boolean';

// components
// import Iconify from 'src/components/iconify';
import { useSnackbar } from 'notistack';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

import { WebServices } from 'src/services/requests';

import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export default function JwtRegisterView() {
  const settings = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const RegisterSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Eski Parola Zorunlu'),
    newPassword: Yup.string()
      .required('Yeni Parola Zorunlu')
      .min(8, 'Parola en az 8 karakter olmalı')
      .max(24, 'Parola en fazla 24 karakter olmalı')
      .matches(/[a-z]/, 'Parola en az 1 küçük harf içermelidir')
      .matches(/\d/, 'Parola en az 1 rakam içermelidir')
      .test(
        'no-sequential-digits',
        'Ardışık sayıları içeremez',
        (value) => !/(012|123|234|345|456|567|678|789)/.test(value)
      )
      .test(
        'no-sequential-letters',
        'Ardışık üç harf içeremez',
        (value) =>
          !/(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz)/i.test(
            value
          )
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Yeni şifre ile eşleşmelidir')
      .required('Onay Parolası Zorunlu'),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const oldPassword = useBoolean();
  const newPassword = useBoolean();
  const confirmPassword = useBoolean();

  const {
    // reset,
    watch,
    // control,
    // setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    const isForm = false;

    const sendData = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };

    const response = await WebServices.updatePassword(sendData, isForm);
    // console.log('create category = ', response);
    if (response.success) {
      enqueueSnackbar('İşlem Başarılı');
      // console.log("dönen başarı register : ",response);
      // reset();
    } else {
      enqueueSnackbar(
        response?.response?.response?.data?.message || 'Beklenmeyen Bir Hata Oluştu',
        {
          variant: 'error',
        }
      );
    }
  });

  const renderForm = (
    <Grid container spacing={isMobile ? 1 : 5} pt={0} mt={0}>
      <Grid item xs={12} md={8}>
        <Stack spacing={isMobile ? 2 : 5} mt={0}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium max-md:text-sm" variant="subtitle2">
                Eski Parolanız
              </Typography>
              <Typography className="text-gray-500 text-sm max-md:text-xs" variant="caption">
                Mevcut Parolanız
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <RHFTextField
                name="oldPassword"
                placeholder="Eski Parolanız"
                type={oldPassword.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={oldPassword.onToggle} edge="end">
                        <Iconify
                          icon={oldPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium max-md:text-sm" variant="subtitle2">
                Yeni Parolanız
              </Typography>
              <Typography className="text-gray-500 text-sm max-md:text-xs" variant="caption">
                Yeni belirleyeceğiniz parolanız
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <RHFTextField
                name="newPassword"
                helperText="Parolanız 8 karakterden uzun, harf ve rakamlardan oluşmalıdır"
                placeholder="Yeni Parolanız"
                type={newPassword.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={newPassword.onToggle} edge="end">
                        <Iconify
                          icon={newPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium max-md:text-sm" variant="subtitle2">
                Yeni Parolanız
              </Typography>
              <Typography className="text-gray-500 text-sm max-md:text-xs" variant="caption">
                Yeni belirleyeceğiniz parola tekrar
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <RHFTextField
                name="confirmPassword"
                placeholder="Yeni Parolanız"
                type={confirmPassword.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={confirmPassword.onToggle} edge="end">
                        <Iconify
                          icon={confirmPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={4} />

            <Grid item xs={12} md={4}>
              <LoadingButton
                fullWidth
                sx={{
                  background: '#F8E71C',
                  // width: '30%',
                  color: '#000',
                  '&:hover': {
                    background: '#F8E71C', // Keep the same background color on hover
                  },
                }}
                size="large"
                type="submit"
                variant="text"
                loading={isSubmitting}
              >
                Parolanızı Güncelleyin
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'} className="container mx-auto">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={2}>
          <div className="flex flex-col gap-2 max-md:gap-0">
            <h1 className="text-3xl font-semibold max-md:text-lg">Parola Güncelleme</h1>
            <p className="opacity-50 text-black max-md:text-xs">
              Parolanızı bu sayfadan güncelleyebilirsiniz.
            </p>
          </div>
          {renderForm}
        </Stack>
      </FormProvider>
    </Container>
  );
}
