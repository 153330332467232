import { Helmet } from 'react-helmet-async';
import AddEdit from 'src/sections/change-password/add-edit';

// ----------------------------------------------------------------------

export default function Page() {
  return (
    <>
      <Helmet>
        <title>Parolamı Değiştir</title>
      </Helmet>

      <AddEdit />
    </>
  );
}
