/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/button-has-type */
// @mui
import Container from '@mui/material/Container';
import _ from 'lodash';
// components
import { Avatar, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSettingsContext } from 'src/components/settings';
import EmptyData from 'src/components/empty/emptyData';
import ErrorSection from 'src/components/error/error';
import LoadingSection from 'src/components/loading-screen/loading-section';

import ForecestList from '../list/listView';
import { useRenderData } from '../context/context';

// ----------------------------------------------------------------------

export default function ManageWiew() {
  const settings = useSettingsContext();

  const { resDataAll } = useRenderData();

  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredData, setFilteredData] = useState(resDataAll);

  useEffect(() => {
    // console.log("resDataAll manage view : ", resDataAll);
    setFilteredData(resDataAll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resDataAll]);


  useEffect(() => {
    const filterMovies = () => {
      if (searchKeyword) {
        const filteredDataTemp = filterMoviesByKeyword(searchKeyword, resDataAll);
        setFilteredData(filteredDataTemp);
      } else {
        setFilteredData(resDataAll);
      }
    };
  
    filterMovies();
  }, [searchKeyword, resDataAll]);

  const filterMoviesByKeyword = (keyword, data) => {
    const lowerCaseKeyword = keyword.replace(/I/g, 'i').replace(/İ/g, 'i').toLowerCase();
  
    return _.chain(data)
      .map(({ releaseDate, upComingMovies }) => ({
        releaseDate,
        upComingMovies: _.filter(upComingMovies, ({ movieName }) =>
          _.includes(_.toLower(movieName), lowerCaseKeyword)
        ),
      }))
      .filter(({ upComingMovies }) => !_.isEmpty(upComingMovies))
      .value();
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <div className="flex flex-col gap-2 mb-10 max-md:mb-5 max-md:gap-0">
        <h1 className="text-3xl max-md:text-lg font-semibold">Yönetebileceğiniz Filmler</h1>
        <p className="opacity-50 max-md:text-xs text-black ">
          Filmlerin görünürlüğünü ayarlama işlemlerini aşağıdan yapabilirsiniz
        </p>
      </div>

      <div className="flex justify-between  items-center  max-md:w-full">
        <div className="max-md:hidden" />
        <div className="flex gap-3  py-0 relative w-96 items-center rounded-lg  max-md:w-full">
          <Avatar
            variant="circular"
            className="absolute right-5"
            src="/assets/extra/search.svg"
            alt=""
            sx={{ width: 20, height: 20, display: 'flex', alignItems: 'center' }}
          />
          <TextField
            className="w-full max-md:w-full"
            label="Film Arama"
            value={searchKeyword}
            InputProps={{
              style: { paddingRight: '36px' },
            }}
            onChange={(event) => {
              setSearchKeyword(event.target.value);
            }}
          />
        </div>
      </div>

      <div className="container mx-auto">
        {filteredData === null ? (
          <LoadingSection />
        ) : filteredData && filteredData.length === 0 ? (
          <EmptyData />
        ) : filteredData ? (
          <ForecestList resDataAll={filteredData} setSearchKeyword={setSearchKeyword} />
        ) : (
          <ErrorSection
            errorTexts={{
              mainMsg: 'Yönetebileceğiniz Filmler Yüklenemedi',
            }}
          />
        )}
      </div>
    </Container>
  );
}
