/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import React from 'react';

import _ from 'lodash';
import { fDate } from 'src/utils/format-time';
import ListItem from './list';

export default function ForecestList({ resDataAll }) {
  return (
    <div className="flex flex-col gap-16 -mt-8  max-md:-m-0 max-md:mt-4 max-md:gap-4">
      {resDataAll?.map((item, index) => {
        const falseIsInVision = _.countBy(item.upComingMovies, { isInVision: true });
        // console.log(falseIsInVision.false);
        // const falseIsForEstimate = _.countBy(item.upComingMovies, { isForEstimate: false });
        // console.log(falseShowStatsMovies?.true > 0 && falseIsForEstimate?.true > 0);
        return (
          falseIsInVision.false && (
            <div key={index}>
              <p className="text-2xl font-bold mb-5 max-md:mb-4 max-md:text-lg">
                {fDate(item.releaseDate)}
              </p>
              <div className="max-lg:flex max-lg:flex-wrap gap-5 max-md:flex-col max-lg:items-start">
                {item.upComingMovies.map(
                  (movieItem, movieIndex) =>
                    !movieItem?.isInVision && (
                      <ListItem
                        key={movieIndex}
                        movieIndex={movieIndex}
                        item={movieItem}
                        date={fDate(item.releaseDate)}
                      />
                    )
                )}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
}
