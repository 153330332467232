import { Helmet } from 'react-helmet-async';
import { RenderDataProvider } from 'src/sections/leadership-board/context/context';
import View from "src/sections/leadership-board/view/view"

// ----------------------------------------------------------------------

export default function Page() {
  return (
    <>
      <Helmet>
        <title>Liderlik Tablosu</title>
      </Helmet>

      <RenderDataProvider>
        <View />
      </RenderDataProvider>
    </>
  );
}
