/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/button-has-type */
// @mui
import Container from '@mui/material/Container';

// components
import { Avatar, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSettingsContext } from 'src/components/settings';
import EmptyData from 'src/components/empty/emptyData';
import ErrorSection from 'src/components/error/error';
import LoadingSection from 'src/components/loading-screen/loading-section';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import ForecestList from '../list/listView';
import { useRenderData } from '../context/context';
import ForecestTopList from '../top-list/listView';

// ----------------------------------------------------------------------

export default function HomeWiew() {
  const settings = useSettingsContext();
  const router = useRouter();

  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredData, setFilteredData] = useState(null);

  const { resDataAll,resDataTopList } = useRenderData();
  // console.log("resDataTopList : ",resDataTopList);

  // console.log("error geldiği zaman :",resDataAll);

  const moviesWithFalseStats = resDataAll
    ?.flatMap((item) => item.upComingMovies)
    .filter((movie) => !movie.isShowStats);

  useEffect(() => {
    if (searchKeyword) {
      const takeFilteredData = filterMoviesByKeyword(searchKeyword);
      setFilteredData(takeFilteredData);
    }
  }, [searchKeyword]);

  const filterMoviesByKeyword = (keyword) => {
    const lowerCaseKeyword = keyword.replace(/I/g, 'i').replace(/İ/g, 'i').toLowerCase();

    return resDataAll
      .map((item) => ({
        releaseDate: item.releaseDate,
        upComingMovies: item.upComingMovies.filter((movie) =>
          movie.movieName.toLowerCase().includes(lowerCaseKeyword)
        ),
      }))
      .filter((item) => item.upComingMovies.length > 0);
  };

  // onClick={() => router.push(paths.dashboard.forecast_information)}

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <div
        style={{ backgroundImage: "url('/assets/extra/banner_bg.webp')" }}
        className="b w-full h-56 mb-10  rounded-lg flex max-md:px-5 max-md:justify-center max-md:items-start items-center justify-between px-16 max-md:flex-col  bg-no-repeat bg-cover"
      >
        <div className="max-md:mb-5">
          <h1 className="text-4xl text-white max-md:text-xl max-lg:text-2xl">
            Biletinial Forecast Nedir?
          </h1>
          <p className="text-xl text-[#D7E2ECD9] max-lg:text-base text-opacity-80 max-md:text-[10px] max-lg:max-w-sm max-xl:max-w-2xl max-md:leading-4">
            Sinema sektörünün paydaşları yapay zeka ile güçlerini birleştiriyor, gişenin geleceğini
            bugün tahmin ediyor.
          </p>
        </div>
        <div
          onClick={() => router.push(paths.dashboard.forecast_information)}
          className="cursor-pointer flex items-center gap-2 h-12 max-md:text-[10px] max-lg:h-9 max-lg:text-sm  bg-white bg-opacity-10 hover:bg-opacity-20 text-white border border-white backdrop-blur-sm rounded px-3  md:flex"
        >
          Detay için tıklayınız
        </div>
      </div>

      {/* Top List */}
      <div className="flex flex-col gap-2 mt-10 mb-20 max-md:mb-5 max-md:gap-0">
        <h1 className="text-3xl max-md:text-lg font-semibold">En Çok Tahminde Bulunan Filmler</h1>
        <p className="opacity-50 max-md:text-xs text-black ">
          En Çok Tahminde Bulunan {resDataTopList?.length} Film Mevcut
        </p>
      </div>

      <div className="container mx-auto">
        {resDataTopList?.length === 0 ? (
          <EmptyData />
        ) : resDataTopList ? (
          <>
            <ForecestTopList resDataAll={resDataTopList} />
            {/* <h1>top list</h1> */}
          </>
        ) : resDataTopList === null ? (
          <LoadingSection />
        ) : (
          <ErrorSection
            errorTexts={{
              mainMsg: 'En Çok Tahminde Bulunan Filmler Yüklenemedi',
            }}
          />
        )}
      </div>

      {/* Main List */}
      <div className="flex flex-col gap-2 mb-10 max-md:mb-5 max-md:gap-0 mt-10">
        <h1 className="text-3xl max-md:text-lg font-semibold">
          Tahminde Bulunabileceğiniz Filmler
        </h1>
        <p className="opacity-50 max-md:text-xs text-black ">
          Tahminde bulunmadığınız {moviesWithFalseStats?.length} film mevcut.
        </p>
      </div>

      <div className="flex justify-between  items-center  max-md:w-full">
        <div className="max-md:hidden" />
        <div className="flex gap-3  py-0 relative w-96 items-center rounded-lg  max-md:w-full">
          <Avatar
            variant="circular"
            className="absolute right-5"
            src="/assets/extra/search.svg"
            alt=""
            sx={{ width: 20, height: 20, display: 'flex', alignItems: 'center' }}
          />
          <TextField
            className="w-full max-md:w-full"
            label="Film Arama"
            value={searchKeyword}
            InputProps={{
              style: { paddingRight: '36px' },
            }}
            onChange={(event) => {
              setSearchKeyword(event.target.value);
            }}
          />
        </div>
      </div>

      <div className="container mx-auto">
        {resDataAll && (searchKeyword === '' ? resDataAll : filteredData)?.length === 0 ? (
          <EmptyData />
        ) : resDataAll ? (
          <>
            {/* <TopList resDataAll={resDataAll} /> */}
            <ForecestList resDataAll={searchKeyword === '' ? resDataAll : filteredData} />
          </>
        ) : resDataAll === null ? (
          <LoadingSection />
        ) : (
          <ErrorSection
            errorTexts={{
              mainMsg: 'Tahminde Bulunabileceğiniz Filmler Yüklenemedi',
            }}
          />
        )}
      </div>
    </Container>
  );
}
