import { Helmet } from 'react-helmet-async';
import { RenderDataProvider } from 'src/sections/user-new/context/context';

import UsersWiew from 'src/sections/user-new/view/usersview';

// import View from 'src/sections/home copy/view/usersview';

// sections

// ----------------------------------------------------------------------

export default function Page() {
  return (
    <>
      <Helmet>
        <title>Kullanıcılar</title>
      </Helmet>

      <RenderDataProvider>
        <UsersWiew />
      </RenderDataProvider>
    </>
  );
}
