/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unsafe-optional-chaining */

import React from 'react';

import { fDate } from 'src/utils/format-time';
import ListItem from './list-item';

export default function FilmsOnSaleList({ resData }) {
  // console.log(resData[0]?.releaseDate);

  // console.log(resData);

  return (
    <>
      {resData.map((item, index) => (
        <div key={index} className="w-full">
          {item.inEstimates.length > 0 && (
            <p className="text-2xl font-bold mb-5 max-md:mb-4 max-md:text-lg">
              {fDate(item.releaseDate)}
            </p>
          )}
          <div className="mb-10 flex flex-wrap gap-5">
            {item.inEstimates.map((movieItem, movieIndex) => (
              <ListItem
                item={movieItem}
                index={movieIndex}
                key={movieIndex + 1}
                date={item?.releaseDate}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
}
