import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
// import Tooltip from '@mui/material/Tooltip';
// import Typography from '@mui/material/Typography';
// auth
// import { useAuthContext } from 'src/auth/hooks';
// routes
// import { paths } from 'src/routes/paths';
// import { RouterLink } from 'src/routes/components';
// hooks
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';

// components

// ----------------------------------------------------------------------

// const METHODS = [
//   {
//     id: 'jwt',
//     label: 'Jwt',
//     path: paths.auth.jwt.login,
//     icon: '/assets/icons/auth/ic_jwt.svg',
//   },
// ];

export default function AuthClassicLayout({ children, image, title }) {
  // const { method } = useAuthContext();

  const theme = useTheme();

  const upMd = useResponsive('up', 'md');

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        // border: "1px solid red",
        mx: 'auto',
        maxWidth: 700,
        px: { xs: 2, md: 8 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // py: { xs: 15, md: 30 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={10}
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
      }}
    >
      {/* <Box
          component="img"
          alt="auth"
          src={image || '/assets/illustrations/illustration_dashboard.webp'}
          sx={{ width: 1, height: 1, objectFit: 'cover' }}
        /> */}
      <Box position="relative" sx={{ width: 1, height: 1 }}>
        {/* <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-l from-zinc-900  z-10' /> */}
        <Avatar
          className="bg-gradient-to-l from-black to-black backdrop-blur object-cover "
          variant="square"
          alt="auth"
          src={image || '/assets/illustrations/illustration_dashboard.webp'}
          sx={{ width: 1, height: 1, objectFit: 'cover' }}
        />

        <Stack
          spacing={3}
          px="10%"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            color: 'white',
            height: 1,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            zIndex: '100',
   
          }}
        >
          <Stack>
            <Typography variant="h3">Gişe Tahmin Aracı</Typography>
            {/* <Typography variant="body1" fontSize="18px" sx={{ color: 'rgba(224, 224, 224, 0.50)' }}>
              Biletinal AI v1.2
            </Typography> */}
          </Stack>

          <Typography fontSize="18px" variant="body1">
            Amerika Sinema Filmleri Derneği`nin başkanı ve CEO`su Jack Valenti 1978 yılında şöyle
            söylemişti.{' '}
            <Typography variant="caption" fontSize="18px" fontWeight={600}>
              “Hiç kimse size bir filmin piyasada nasıl performans göstereceğini söyleyemez! Ta ki
              film sinema salonunun karanlığını aydınlatıncaya ve perde ile seyirci arasında
              kıvılcımlar uçuşmaya başlayıncaya kadar… ”
            </Typography>
          </Typography>
          <Typography fontSize="18px" variant="body1">
            Biletinial olarak, Bay Valenti’nin bu iddiasını sinema tarihinin tozlu sayfalarında
            bırakıyoruz. Geliştirdiğimiz “Gişe Tahmin Aracı” sektör paydaşlarımızın öngörülerini
            yapay zeka teknolojisi ile bir araya getiriyor ve bu sayede gişenin önündeki sis perdesi
            aralanıyor.
          </Typography>
          <Typography fontSize="18px" variant="body1">
            Biletinial AI’ın bizlere en sağlıklı tahminleri sunmasının koşulu ise onu besleyecek
            olan en nitelikli ve en geniş kitleye ulaşabilmesi, yani değerli sinema sektörü
            paydaşlarının öngörüleri. Tabi ki tahmin yürütmenin ve doğru tahminleme konusunda tatlı
            bir rekabetin tadı da işin keyifli yönü.
          </Typography>
          <Typography fontSize="18px" variant="body1">
            Biletinial Gişe AI’ya hoş geldiniz! Ne dersiniz, birlikte denemeye başlayalı mı?
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
       {renderContent}
      {upMd && renderSection}

     
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
