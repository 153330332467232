// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      forget_password: `${ROOTS.AUTH}/jwt/forget-password`,
      forget_password_change: `${ROOTS.AUTH}/jwt/forget-password-change`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    home: `${ROOTS.DASHBOARD}/dashboard`,
    forecast_information: `${ROOTS.DASHBOARD}/forecast-information`,
    my_forecases: `${ROOTS.DASHBOARD}/my-forecases`,
    leaderboard: `${ROOTS.DASHBOARD}/leaderboard`,
    profile: `${ROOTS.DASHBOARD}/profile`,
    changePassword: `${ROOTS.DASHBOARD}/change-password`,
    users: `${ROOTS.DASHBOARD}/users`,
    completedMovies: `${ROOTS.DASHBOARD}/completed-movies`,
    manageMovies: `${ROOTS.DASHBOARD}/manage-movies`,
  },
};
