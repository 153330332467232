/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
import { Avatar, Button, Dialog, useTheme, useMediaQuery, Stack } from '@mui/material';
import React, { useState } from 'react';

import { fNumber } from 'src/utils/format-number';
// import Iconify from 'src/components/iconify';

import Iconify from 'src/components/iconify';
import ListItemDetailsMyForecast from 'src/sections/my-forecastes/list/films-on-sale/list-item-details';
import Add from '../add/add';
import ListItemDetails from './after-success-page';
import { useRenderData } from '../context/context';
import InfoDialog from '../list/InfoDialog';

export default function TopList({ item, date, movieIndex }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenInfo, setDialogOpenInfo] = useState(false);
  const { setResDataTopList, fetchTopData } = useRenderData();
  const [isSuccessRes, setIsSuccessRes] = useState(false);

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  // console.log('top list item : ', item);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const showImagesCount = isMobile ? 3 : 5;

  const handleOpenDialog = () => setDialogOpen(true);

  const handleCloseDialogInfo = async () => {
    setDialogOpenInfo(false);
  };

  const handleCloseDialog = async () => {
    if (isSuccessRes) {
      await setResDataTopList(null);
      await fetchTopData();
      setIsSuccessRes(false);
    }
    setDialogOpen(false);
  };

  return (
    <>
      <div className="w-full  flex-col border border-solid mb-3 max-md:mb-0 border-[#DFDFDF] rounded-lg shadow-md p-5 max-md:p-4 max-lg:w-[calc(50%-10px)] max-md:w-full ">
        <div className="flex items-center justify-between w-full max-lg:flex-col max-lg:gap-5">
          <div
            onClick={() => setDialogOpenInfo(true)}
            className="flex gap-5 cursor-pointer items-center min-w-[500px] max-lg:max-w-full  max-lg:min-w-fit max-xl:min-w-[250px] max-xl:max-w-[250px] max-lg:w-full max-lg:border-solid max-lg:border-b max-lg:border-gray-200 max-lg:pb-4"
          >
            <Avatar
              src={item?.imageUrl}
              className="w-16 h-auto min-h-[90px] max-h-[95px] rounded-lg"
              alt={item?.movieName}
            />
            <div>
              <h2 className="text-lg text-black font-semibold max-w-xs max-lg:max-w-full  max-xl:max-w-[200px] overflow-hidden line-clamp-2 max-md:text-base">
                {item?.movieName}
              </h2>
              <p className="text-gray-500 font-medium text-sm max-md:text-xs">{item?.genre}</p>
            </div>
          </div>
          <div className="flex gap-10 max-lg:w-full max-xl:min-w-[250px] items-center max-lg:justify-between max-lg:items-start max-lg:border-solid max-lg:border-b max-lg:border-gray-300 max-lg:pb-4  max-lg:gap-5 ">
            <div className="flex flex-col items-end max-lg:justify-start max-lg:items-start">
              <h3 className="opacity-50 text-sm">Tahmin Sayısı</h3>
              <div className="text-2xl font-bold max-md:text-lg">{item?.totalEstimators}</div>
            </div>
            <div className="flex min-w-[300px] max-xl:min-w-fit">
              <div className="isolate flex -space-x-3 overflow-hidden">
                {item?.estimators?.slice(0, showImagesCount).map((imgItem, index) => (
                  <Avatar
                    className="w-12 h-12 border-4 border-solid border-white bg-white"
                    key={index}
                    src={imgItem}
                    width={46}
                    height={46}
                    alt="person"
                  />
                ))}
                {item?.totalEstimators - showImagesCount > 0 && (
                  <div
                    className="relative border-2 border-solid border-gray-100  h-12 w-12 rounded-full bg-gray-200 text-white text-center flex justify-center items-center text-xs font-semibold"
                    width={46}
                    height={46}
                  >
                    +{item?.totalEstimators - showImagesCount}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex gap-5">
            <div
              className={`${
                !item?.isShowStats
                  ? 'flex-col items-start flex min-w-[250px] max-lg:max-w-full  max-lg:w-full max-xl:min-w-[250px] '
                  : 'hidden'
              }`}
            >
              <p className="text-sm opacity-50 pl-2 max-md:pl-0">Tahmini Seyirci Sayısı</p>
              <Button
                className="font-bold not-italic text-[#0071E3] max-md:pl-0"
                onClick={handleOpenDialog}
              >
                {' '}
                Görebilmek için tahminde bulun →
              </Button>
            </div>
            <div
              className={`${
                item?.isShowStats
                  ? 'flex gap-9 justify-end min-w-[250px] max-lg:max-w-full  max-lg:w-full max-xl:min-w-[250px] max-lg:justify-between '
                  : 'hidden'
              }`}
            >
              <div className="flex flex-col items-end max-xl:items-start">
                <p className="text-gray-500 text-sm ">Tahmininiz</p>
                <div className="text-2xl font-bold max-xl:text-xl">
                  {fNumber(item?.userEstimate)}
                </div>
              </div>
            </div>
            {item.isShowStats ? (
              <div className="w-full flex justify-end items-center">
                <Button
                  sx={{ borderRadius: '30%' }}
                  onClick={async () => {
                    await setSelectedIndex(movieIndex);
                    await setCollapseOpen(!collapseOpen);
                  }}
                >
                  <Iconify
                    icon={
                      collapseOpen && selectedIndex === movieIndex ? 'ph:arrow-up' : 'ph:arrow-down'
                    }
                  />
                </Button>
              </div>
            ) : (
              <div className="w-full flex justify-end items-center">
                <Button
                  disabled
                  sx={{
                    cursor: 'auto',
                    borderRadius: '30%',
                    '&:hover': {
                      backgroundColor: 'transparent', // Hover durumunda arka planı şeffaf yapabilirsiniz.
                    },
                  }}
                >
                  <Iconify icon="ph:warning" />
                </Button>
              </div>
            )}
          </div>
        </div>
        {collapseOpen && selectedIndex === movieIndex && (
          <ListItemDetailsMyForecast movieId={item.movieId} />
        )}
      </div>

      <Dialog open={dialogOpenInfo} onClose={handleCloseDialogInfo} maxWidth="md">
        <Stack p={5} position="relative">
          <Button
            variant="outlined"
            color="inherit"
            sx={{ position: 'absolute', top: 40, right: 40, border: 'none' }}
            onClick={handleCloseDialogInfo}
          >
            <Avatar
              src="/assets/extra/close_ic.svg"
              alt="biletinial"
              variant="square"
              sx={{
                width: 19,
                height: 19,
              }}
            />
          </Button>
          <InfoDialog item={item} />
        </Stack>
      </Dialog>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="xl">
        {!isSuccessRes ? (
          <Add
            item={item}
            date={date}
            handleCloseDialog={handleCloseDialog}
            setIsSuccessRes={setIsSuccessRes}
          />
        ) : (
          <ListItemDetails movieId={item.movieId} handleCloseDialog={handleCloseDialog} />
        )}
      </Dialog>
    </>
  );
}
