/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
import {
  Avatar,
  Switch,
  Stack,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { WebServices } from 'src/services/requests';
import { useBoolean } from 'src/hooks/use-boolean';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useRenderData } from '../context/context';

export default function List({ item, setSearchKeyword }) {
  const { resDataAll, setResDataAll } = useRenderData();

  const [loading, setLoading] = useState(false);
  const confirm = useBoolean();

  const handleChange = () => {
    confirm.onTrue();
  };

  const { enqueueSnackbar } = useSnackbar();

  const forecastOnSubmit = async () => {
    try {
      setLoading(true);
      const isForm = false;
      const data = {
        movieId: item.movieId,
        isForEstimate: !item?.isForEstimate,
      };

      const response = await WebServices.updateMovieEstimate(data, isForm);

      if (response.success) {
        const message = !item?.isForEstimate
          ? `${item?.movieName} filmi tahminde bulunabilecek filmlere eklendi.`
          : `${item?.movieName} filmi tahminde bulunabilecek filmlerden kaldırıldı.`;

        enqueueSnackbar(message);

        await updateMovieData(item.movieId, !item?.isForEstimate);
        await new Promise((resolve) => setTimeout(resolve, 200));
      } else {
        enqueueSnackbar('Hatalı İşlem', { variant: 'error' });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateMovieData = async (movieId, newIsForEstimate) => {
    try {
      const updatedResDataAll = _.cloneDeep(resDataAll);

      const movieToUpdate = _.find(updatedResDataAll, (release) =>
        _.find(release.upComingMovies, { movieId })
      );

      if (movieToUpdate) {
        const movieIndex = updatedResDataAll.indexOf(movieToUpdate);
        const movieIdIndex = _.findIndex(movieToUpdate.upComingMovies, { movieId });

        _.set(
          updatedResDataAll,
          [movieIndex, 'upComingMovies', movieIdIndex, 'isForEstimate'],
          newIsForEstimate
        );

        await setResDataAll(updatedResDataAll);
      } else {
        console.error(`Film with movieId ${movieId} not found.`);
      }
    } catch (error) {
      console.error('An error occurred while updating movie data:', error);
    }
  };

  return (
    <>
      {loading && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="w-full  flex-col border border-solid mb-3 max-md:mb-0 border-[#DFDFDF] rounded-lg shadow-md p-5 max-md:p-4 max-lg:w-[calc(50%-10px)] max-md:w-full ">
        <div className="flex items-center justify-between w-full max-lg:flex-col max-lg:gap-5">
          <div className="flex gap-5 items-center min-w-[500px] max-lg:max-w-full  max-lg:min-w-fit max-xl:min-w-[250px] max-xl:max-w-[250px] max-lg:w-full max-lg:border-solid max-lg:border-b max-lg:border-gray-200 max-lg:pb-4">
            <Avatar
              src={item?.imageUrl}
              className="w-16 h-auto min-h-[90px] max-h-[95px] rounded-lg"
              alt={item?.movieName}
            />
            <div>
              <h2 className="text-lg text-black font-semibold max-w-xs max-lg:max-w-full  max-xl:max-w-[200px] overflow-hidden line-clamp-2 max-md:text-base">
                {item?.movieName}
              </h2>
              <p className="text-gray-500 font-medium text-sm max-md:text-xs">{item?.genre}</p>
            </div>
          </div>

          <div className="flex-col items-end flex min-w-[250px]  max-lg:items-start max-lg:max-w-full  max-lg:w-full max-xl:min-w-[250px]">
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle1">Film Gösterilsin mi?</Typography>

              <Switch
                checked={item?.isForEstimate}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Stack>
          </div>
        </div>
      </div>

      <ConfirmDialog
        open={confirm.value}
        isImg={item?.imageUrl}
        onClose={confirm.onFalse}
        title={`${item?.movieName} filmi tahminde bulunabilecek  ${
          !item?.isForEstimate ? 'filmlerde yer alacak.' : 'filmlerden kaldırılacak.'
        }`}
        content="Onaylıyor Musunuz?"
        action={
          <Button
            className="px-8"
            variant="contained"
            color={!item?.isForEstimate ? 'success' : 'error'}
            onClick={() => {
              confirm.onFalse();
              forecastOnSubmit();
            }}
          >
            {!item?.isForEstimate ? ' Onayla' : 'Kaldır'}
          </Button>
        }
      />
    </>
  );
}
