/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import { Button, Skeleton } from '@mui/material';
import React, { useState } from 'react';
import _ from 'lodash';
import EmptyData from 'src/components/empty/emptyData';
// import FilmsOnSaleList from './films-on-sale/list';
import FilmsCompalted from './films-complated/list';
import FilmsOnSaleList from './films-on-sale/list';

export default function MyForecestList({ resDataAll }) {
  const [page, setpage] = useState(0);

  const totalEstimatedHistoriesLength = _.sum(
    resDataAll?.estimatedHistory?.map((item) => item?.estimatedHistories?.length) ?? [0]
  );

  const totalInEstimatedLength = _.sum(
    resDataAll?.inEstimate?.map((item) => item?.inEstimates?.length) ?? [0]
  );

  return (
    <div className="flex flex-col gap-3 -mt-12  max-lg:mt-5">
      <div className="flex gap-3 mb-10 max-md:mb-2">
        <Button
          className="max-lg:px-3 max-lg:py-2 max-lg:text-xs max-md:text-left"
          onClick={() => setpage(0)}
          sx={{
            border: page === 0 ? '#0071E3' : '#000',
            borderWidth: '1px',
            borderStyle: 'solid',
            color: page === 0 ? '#0071E3' : '#000',
            padding: '10px 32px',
          }}
          disabled={totalInEstimatedLength === 0}
        >
          Satışı Devam Eden Filmler
          <span
            className={
              page === 0
                ? `ml-2 bg-[#0071E3] px-2 rounded text-white font-bold`
                : `ml-2 bg-[#000] px-2 rounded text-white font-bold`
            }
          >
            {totalInEstimatedLength}
          </span>
        </Button>
        <Button
          className="max-lg:px-3 max-lg:py-2 max-lg:text-xs max-md:text-left"
          onClick={() => setpage(1)}
          sx={{
            border: page === 1 ? '#0071E3' : '#000',
            borderWidth: '1px',
            borderStyle: 'solid',
            color: page === 1 ? '#0071E3' : '#000',
            padding: '10px 32px',
          }}
          disabled={totalEstimatedHistoriesLength === 0}
        >
          Tahmin Süresi Dolan Filmler
          <span
            className={
              page === 1
                ? `ml-2 bg-[#0071E3] px-2 rounded text-white font-bold`
                : `ml-2 bg-[#000] px-2 rounded text-white font-bold`
            }
          >
            {totalEstimatedHistoriesLength}
          </span>
        </Button>
      </div>

      {resDataAll ? (
        page === 0 ? (
          totalInEstimatedLength === 0 ? (
            <EmptyData />
          ) : (
            <div className="max-lg:flex max-lg:flex-wrap gap-5 max-md:gap-4 max-md:flex-col max-lg:items-start">
              <FilmsOnSaleList resData={resDataAll.inEstimate} />
              {/* <FilmsOnSaleListNew resData={resDataAll.inEstimate} /> */}
            </div>
          )
        ) : totalEstimatedHistoriesLength === 0 ? (
          <EmptyData />
        ) : (
          <div className="max-lg:flex max-lg:flex-wrap gap-5 max-md:gap-4 max-md:flex-col max-lg:items-start">
            <FilmsCompalted resData={resDataAll.estimatedHistory} />
          </div>
        )
      ) : (
        <Skeleton width="100%" height={380} animation="pulse" />
      )}
    </div>
  );
}
