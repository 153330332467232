/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// import { Avatar } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import { WebServices } from 'src/services/requests';
import { Avatar, Button, Skeleton, useTheme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { fDate } from 'src/utils/format-time';
import { fNumber } from 'src/utils/format-number';
import { WebServices } from 'src/services/requests';
import EmptyData from 'src/components/empty/emptyData';
import ChartRadialBar from 'src/components/chart/chart-view/chart-radial-bar';
// import ListItemDetailsChart from './list-item-details-chart';

export default function AfterSuccessListItemDetails({ movieId, handleCloseDialog }) {
  const [resData, setResData] = useState(null);
  async function fetchDropDownData() {
    const { data } = await WebServices.getMovieEstimateDetails({ MovieId: movieId });
    setResData(data?.data);
  }

  useEffect(() => {
    fetchDropDownData({ MovieId: movieId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const showImagesCount = isMobile ? 3 : 5;

  return (
    <>
      {resData ? (
        <div className="container  mx-auto p-10 max-md:p-5 max-xl:p-5">
          <div>
            <div className="mb-12 flex justify-between max-md:mb-5">
              <div>
                <h2 className="text-3xl font-semibold mb-1 max-md:text-xl">
                  Tahmininiz için teşekkürler!
                </h2>
                <p className="text-gray-500 max-md:text-xs">Filmin tahmin kilidi açıldı!</p>
              </div>
              <Button onClick={handleCloseDialog}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <g clipPath="url(#clip0_574_964)">
                    <path
                      d="M0.927908 19C0.744394 19 0.564993 18.9456 0.412396 18.8437C0.259799 18.7418 0.140862 18.5969 0.0706297 18.4273C0.00039741 18.2578 -0.0179751 18.0712 0.017836 17.8912C0.0536472 17.7112 0.142033 17.5459 0.271814 17.4162L17.4163 0.271641C17.5904 0.097634 17.8264 -0.00012207 18.0724 -0.00012207C18.3185 -0.00012207 18.5545 0.097634 18.7285 0.271641C18.9025 0.445648 19.0003 0.681652 19.0003 0.927735C19.0003 1.17382 18.9025 1.40982 18.7285 1.58383L1.584 18.7284C1.49792 18.8146 1.39565 18.883 1.28306 18.9296C1.17046 18.9763 1.04977 19.0002 0.927908 19Z"
                      fill="black"
                    />
                    <path
                      d="M18.0724 19C17.9505 19.0002 17.8298 18.9763 17.7172 18.9296C17.6046 18.883 17.5024 18.8146 17.4163 18.7284L0.271763 1.58383C0.0977561 1.40982 0 1.17382 0 0.927735C0 0.681652 0.0977561 0.445648 0.271763 0.271641C0.44577 0.097634 0.681774 -0.00012207 0.927857 -0.00012207C1.17394 -0.00012207 1.40994 0.097634 1.58395 0.271641L18.7285 17.4162C18.8583 17.5459 18.9466 17.7112 18.9825 17.8912C19.0183 18.0712 18.9999 18.2578 18.9297 18.4273C18.8594 18.5969 18.7405 18.7418 18.5879 18.8437C18.4353 18.9456 18.2559 19 18.0724 19Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_574_964">
                      <rect width="19" height="19" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Button>
            </div>

            <div className="border border-solid border-gray-200 rounded-lg p-5 max-xl:p-2">
              <div className="flex items-center justify-between max-lg:flex-col max-lg:gap-5 max-xl:p-2 p-5">
                <div className="flex gap-5 items-center  min-w-[350px] max-w-[350px]  max-xl:min-w-[250px] max-xl:max-w-[250px] max-2xl:min-w-fit max-lg:min-w-full max-lg:w-full max-lg:border-solid max-lg:border-b max-lg:border-gray-200 max-lg:pb-3">
                  <Avatar
                    src={resData?.movieStats?.imageUrl}
                    className="w-16 h-20 rounded-lg"
                    alt={resData?.movieStats?.movieName}
                  />
                  <div>
                    <h2 className="text-lg text-black font-semibold max-w-xs overflow-hidden line-clamp-1">
                      {resData?.movieStats?.movieName}
                    </h2>
                    <p className="text-gray-500 font-medium text-sm">
                      Vizyon Tarihi: {fDate(resData?.movieStats?.releaseDate)}
                    </p>
                  </div>
                </div>
                <div className="flex gap-10 max-xl:gap-5 max-lg:w-full max-md:items-start max-lg:justify-between min-w-[350px] max-w-[350px] max-xl:min-w-[320px] max-xl:max-w-[320px] items-center max-lg:min-w-full  max-md:gap-5 max-lg:border-solid max-lg:border-b max-lg:border-gray-200 max-lg:pb-3">
                  <div className="flex flex-col items-end max-md:items-start">
                    <h3 className="opacity-50 text-sm whitespace-nowrap max-md:text-xs max-xl:text-xs">
                      Tahmin Sayısı
                    </h3>
                    <div className="text-2xl font-bold max-md:text-lg max-xl:text-lg">
                      {fNumber(resData?.movieStats?.totalEstimators)}
                    </div>
                  </div>

                  <div className="flex min-w-[200px] max-w-[250px] max-2xl:min-w-fit max-lg:w-full">
                    <div className="isolate flex -space-x-3 overflow-hidden">
                      {resData?.movieStats?.estimators
                        ?.slice(0, showImagesCount)
                        .map((imgItem, i) => (
                          <Avatar
                            className="w-12 h-12 border-4 border-solid border-white shadow-sm bg-white"
                            key={i}
                            src={imgItem}
                            width={46}
                            height={46}
                            alt="person"
                          />
                        ))}
                      {resData?.movieStats?.totalEstimators - showImagesCount > 0 && (
                        <div
                          className="relative border-2 border-solid border-gray-100  h-12 w-12 rounded-full bg-gray-200 text-white text-center flex justify-center items-center text-xs font-semibold"
                          width={46}
                          height={46}
                        >
                          +{resData?.movieStats?.totalEstimators - showImagesCount}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={` flex gap-6 max-2xl:gap-4 justify-end max-w-[470px] max-lg:justify-between  min-w-[470px] max-2xl:min-w-fit max-lg:min-w-fit max-lg:w-full max-md:flex-col `}
                >
                  <div className="flex flex-col items-end max-md:items-start max-md:border-solid max-md:border-b max-md:border-gray-200 max-lg:pb-3">
                    <p className="opacity-50 text-sm max-md:text-xs max-xl:text-xs">
                      Genel Tahmin Ortalaması
                    </p>
                    <div className="text-2xl font-bold max-md:text-lg max-xl:text-lg">
                      {fNumber(resData?.movieStats?.movieAvarage)}
                    </div>
                  </div>
                  <div className="flex flex-col items-end max-md:items-start max-md:border-solid max-md:border-b max-md:border-gray-200 max-lg:pb-3">
                    <p className="text-gray-500 text-sm max-md:text-xs max-xl:text-xs">
                      Tahmininiz
                    </p>
                    <div className="text-2xl font-bold max-md:text-lg  max-xl:text-lg">
                      {fNumber(resData?.movieStats?.userEstimate)}
                    </div>
                  </div>
                  <div className="flex gap-2 items-end max-md:items-start  max-lg:pb-3">
                    <img
                      src={`/assets/extra/${
                        resData?.movieStats?.userRate > 50 ? 'rank_green' : 'rank'
                      }.svg`}
                      className={`rounded-full ${
                        resData?.movieStats?.userRate > 50 ? 'bg-green-200' : 'bg-red-200'
                      } p-1 w-8 h-8`}
                      // variant="square"
                      width={30}
                      height={30}
                      alt={resData?.movieStats?.movieName}
                    />
                    <div className="flex items-end text-base font-semibold">
                      {resData?.movieStats?.userRate} %
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full max-xl:p-2 flex justify-between pt-5  border-t border-solid border-gray-200 p-5  max-lg:flex-col">
                <div className="flex-1 max-lg:py-3">
                  <h2 className="text-xl font-semibold mb-5 text-black max-xl:text-base">
                    Seyirci Tahmininiz
                  </h2>
                  <div className="flex max-lg:flex-col  items-start">
                    <div className="flex flex-col gap-7 max-lg:gap-2">
                      <div className="flex flex-col gap-1 max-lg:gap-0">
                        <span className="text-sm  text-gray-500 max-xl:text-xs">Tahmininiz</span>
                        <div className="text-2xl max-lg:text-lg font-bold  max-xl:text-base">
                          {fNumber(resData?.movieStats?.userEstimate)}
                        </div>
                      </div>
                      <div className="flex flex-col gap-1 max-lg:gap-0">
                        <span className="text-sm text-gray-500 max-xl:text-xs">
                          Genel Tahmin Ortalaması
                        </span>
                        <div className="text-2xl  max-lg:text-lg font-bold max-xl:text-base">
                          {fNumber(resData?.movieStats?.movieAvarage)}
                        </div>
                      </div>

                      <div className="flex flex-col gap-1 max-lg:gap-0">
                        <span className="text-sm text-gray-500 max-xl:text-xs">
                          Genel Tahmine Oranınız
                        </span>
                        <div className="text-2xl  max-lg:text-lg font-bold max-xl:text-base ">
                          {fNumber(resData?.movieStats?.userRate)}%
                        </div>
                      </div>
                    </div>

                    {/* <ListItemDetailsChart value={resData?.movieStats?.userRate || 0} /> */}
                    <ChartRadialBar
                      series={[resData?.movieStats?.userRate || 0]}
                      labels={['Genel Tahmin Ortalamasına Göre']}
                      value={resData?.movieStats?.userRate || 0}
                    />
                  </div>
                </div>
                <div className="flex-1 max-lg:py-5 max-lg:border-b max-lg:border-solid max-lg:border-gray-200 ">
                  <h2 className="text-xl font-semibold mb-5 text-black max-xl:text-base">
                    Sektörel Tahminler
                  </h2>
                  <div className="flex flex-col gap-7 max-lg:gap-2">
                    {resData?.userTypeStats.length === 0
                      ? emptyData.map((user, index) => (
                          <div key={index} className="flex flex-col gap-1 max-lg:gap-0">
                            <span className="text-sm text-gray-500 max-xl:text-xs">
                              {user?.userTypes}
                            </span>
                            <div className="text-base  font-bold max-xl:text-xs">
                              Henüz Hesaplanmadı
                            </div>
                          </div>
                        ))
                      : resData?.userTypeStats?.map((user) => (
                          <div className="flex flex-col gap-1 max-lg:gap-0">
                            <span className="text-sm text-gray-500">{user?.userTypes}</span>
                            <div className="text-2xl max-lg:text-lg font-bold ">
                              {fNumber(user?.userTypeAvarage)}{' '}
                              <span className="text-sm font-normal">
                                / En düşük {fNumber(user?.minEstimate)} En yüksek{' '}
                                {fNumber(user?.maxEstimate)}
                              </span>
                            </div>
                          </div>
                        ))}
                    {/* <div className="flex flex-col gap-1 max-lg:gap-0">
                      <span className="text-sm text-gray-500">Dağıtımcı Tahmin Ortalaması</span>
                      <div className="text-2xl max-lg:text-lg font-bold ">
                        {resData[0]?.UserTypesRates[1]?.Rate}{' '}
                        <span className="text-sm font-normal">
                          / En düşük 140.000 En yüksek 400.000
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 max-lg:gap-0">
                      <span className="text-sm text-gray-500">Yapımcı Ortalaması</span>
                      <div className="text-2xl max-lg:text-lg font-bold ">
                        {resData[0]?.UserTypesRates[2]?.Rate}{' '}
                        <span className="text-sm font-normal">
                          / En düşük 140.000 En yüksek 400.000
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 max-lg:gap-0">
                      <span className="text-sm text-gray-500">Sinema Müdürleri Ortalaması</span>
                      <div className="text-2xl max-lg:text-lg font-bold ">
                        {resData[0]?.UserTypesRates[3]?.Rate}{' '}
                        <span className="text-sm font-normal">
                          / En düşük 140.000 En yüksek 400.000
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className=" max-lg:py-5">
                  <h2 className="text-xl font-semibold mb-5 text-black max-xl:text-lg">
                    Tahmin Liderlik Tablosu
                  </h2>
                  <div className="flex flex-col w-80  max-lg:w-full">
                    {tempLeaderBoards?.map((item, index) => (
                      //   console.log(item);
                      <div
                        className="flex items-center justify-between last-of-type:border-none py-2 border-b border-solid border-gray-200"
                        key={index}
                      >
                        <div className="flex items-center">
                          <div
                            className={`min-w-[30px] font-bold text-${
                              item?.isOwnUser && '[#0071E3]'
                            }`}
                          >
                            {item?.rank}
                          </div>

                          <Avatar
                            className=" mr-3"
                            src={item?.profilePhotos}
                            width={40}
                            height={40}
                            alt={item?.userNameSurname}
                          />
                          <p
                            className={`font-semibold text-base text-${
                              item?.isOwnUser && '[#0071E3]'
                            }`}
                          >
                            {item?.isOwnUser ? 'Siz' : item?.userNameSurname}
                          </p>
                        </div>
                        {index === 0 && (
                          <Avatar
                            className=" mr-3 w-6 h-6"
                            src="/assets/extra/king.svg"
                            width={24}
                            height={24}
                            alt={item?.userNameSurname}
                          />
                        )}
                        <div />
                        <div className={`font-semibold~ text-${item?.isOwnUser && '[#0071E3]'}`}>
                          {item?.score}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : resData?.estimateStats === null ? (
        <EmptyData text="Bu film için henüz analiz yapılmadı" small />
      ) : (
        <Skeleton variant="text" width="100%" height={340} animation="pulse" />
      )}
    </>
  );
}

const emptyData = [
  {
    userTypes: 'Sinema İşletmecisi Tahmin Ortalaması',
  },
  {
    userTypes: 'Dağıtımcı Tahmin Ortalaması',
  },
  {
    userTypes: 'Yapımcı Ortalaması',
  },
];

const tempLeaderBoards = [
  {
    rank: 1,
    userNameSurname: 'Kullanıcı 1',
    profilePhotos: '/assets/extra/user(temp).svg',
    score: '-',
    isOwnUser: false,
  },
  {
    rank: 2,
    userNameSurname: 'Kullanıcı 2',
    profilePhotos: '/assets/extra/user(temp).svg',
    score: '-',
    isOwnUser: false,
  },
  {
    rank: 3,
    userNameSurname: 'Kullanıcı 3',
    profilePhotos: '/assets/extra/user(temp).svg',
    score: '-',
    isOwnUser: false,
  },
];
