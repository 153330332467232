import React from 'react';
import { Helmet } from 'react-helmet-async';
import View from 'src/sections/forecast-information/view';

export default function ForecastInformation() {
  return (
    <>
      <Helmet>
        <title>Forecasts Nedir?</title>
      </Helmet>
      <View />
    </>
  );
}
