/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/button-has-type */
// @mui
import Container from '@mui/material/Container';
// components
import { Avatar, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSettingsContext } from 'src/components/settings';
import EmptyData from 'src/components/empty/emptyData';
import ErrorSection from 'src/components/error/error';
import LoadingSection from 'src/components/loading-screen/loading-section';
import ForecestList from '../list/list';
import { useRenderData } from '../context/context';
// ----------------------------------------------------------------------

export default function CompletedWiew() {
  const settings = useSettingsContext();

  const [searchKeyword, setSearchKeyword] = useState('');
  const [waitingFilterData, setWaitingFilterData] = useState(null);
  const [changeFilter, setChangeFilter] = useState(true);

  const { resDataAll } = useRenderData();

  const mergedMovies = _.map(
    [...(resDataAll?.waitingMovies || []), ...(resDataAll?.completedMovies || [])],
    (movie) => ({
      ...movie,
      isWaitingMovie: _.includes(resDataAll?.waitingMovies, movie),
    })
  );

  useEffect(() => {
    setWaitingFilterData(_.filter(mergedMovies, { isWaitingMovie: changeFilter }));
  }, [resDataAll, changeFilter]);

  // console.log('tamamlanan filmler : ', resDataAll);
  // console.log('mergedMovies filmler : ', mergedMovies);
  // console.log('waitingFilter filmler : ', waitingFilterData);

  // Arama anahtarına göre filtrelenmiş filmleri içerecek yeni bir dizi tanımlanır
  const filteredMovies = mergedMovies?.filter((movie) =>
    movie.movieName
      .toLowerCase()
      .includes(searchKeyword.replace(/I/g, 'i').replace(/İ/g, 'i').toLowerCase())
  );

  const filteredWaitingFilterData = waitingFilterData?.filter((movie) =>
    movie.movieName
      .toLowerCase()
      .includes(searchKeyword.replace(/I/g, 'i').replace(/İ/g, 'i').toLowerCase())
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <div className="container mx-auto">
        <div className="flex flex-col max-md:gap-0 gap-2 mb-10 max-md:mb-5">
          <h1 className="text-3xl  font-semibold max-md:text-lg">Filmler</h1>
          <p className="opacity-50  text-black max-md:text-xs">
            Filmleri buradan görebilir, skorlayabilir ve güncelleyebilirsiniz.
          </p>
        </div>

        <div className="flex justify-between  items-center max-xl:items-start max-xl:justify-start  max-md:w-full">
          <div className="max-md:hidden" />
          <div className="flex gap-3  py-0 relative w-96 items-center rounded-lg  max-md:w-full">
            <Avatar
              variant="circular"
              className="absolute right-5"
              src="/assets/extra/search.svg"
              alt=""
              sx={{ width: 20, height: 20, display: 'flex', alignItems: 'center' }}
            />
            <TextField
              className="w-full"
              label="Film Arama"
              value={searchKeyword}
              InputProps={{
                style: { paddingRight: '36px' },
              }}
              onChange={(event) => {
                setSearchKeyword(event.target.value);
              }}
            />
          </div>
        </div>

        {resDataAll && resDataAll?.length === 0 ? (
          <EmptyData />
        ) : resDataAll ? (
          <ForecestList
            resDataAll={filteredMovies}
            waitingFilterData={filteredWaitingFilterData}
            changeFilter={changeFilter}
            setChangeFilter={setChangeFilter}
          />
        ) : resDataAll === null ? (
          <LoadingSection />
        ) : (
          <ErrorSection
            errorTexts={{
              mainMsg: 'Filmler Yüklenemedi',
            }}
          />
        )}
      </div>
    </Container>
  );
}
