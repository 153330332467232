/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import React from 'react';
import Container from '@mui/material/Container';
import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';

// import { useTheme } from '@mui/material';

// import useMediaQuery from '@mui/material/useMediaQuery';

import { Avatar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSettingsContext } from 'src/components/settings';

// eslint-disable-next-line import/no-unresolved

export default function View() {
  const settings = useSettingsContext();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const mailText = (
    <Typography variant="body1">
      Evet, katılımcılar ve sistem kullanıcıları, her zaman sistemi geliştirmeye yönelik öneri ve
      geri bildirimlerde bulunabilirler. Bu geri bildirimler, sistem yöneticileri tarafından dikkate
      alınacak ve gerektiğinde uygulamaya konulacaktır. Geri bildirimde bulunmak için
      <a
        href="mailto:cihan.yazici@biletinial.com"
        className="font-semibold text-indigo-600 hover:text-indigo-500"
      >
        {' '}
        cihan.yazici@biletinial.com
      </a>{' '}
      adresine ileti gönderebilirsiniz.
    </Typography>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <div className="flex items-start w-full gap-10 justify-between max-xl:flex-wrap">
        <div className="w-2/3 max-xl:w-full">
          <h1 className="text-3xl font-semibold max-md:text-lg max-md:leading-3">
            {data[0].head1}
          </h1>
          <span className="text-gray-500 max-md:text-xs max-md:leading-3">Biletinial AI 1.0</span>
          <div className="flex flex-col gap-5 mt-5 text-xl leading-7 max-md:text-sm max-w-[812px] max-xl:max-w-full  text-black">
            <p>{data[0].detail}</p>
            <p>{data[0].detail2}</p>
          </div>
        </div>
        <div className="w-1/3 max-xl:w-full">
          <Avatar
            variant="square"
            src="/assets/extra/ForecastInf.svg"
            alt=""
            sx={{ width: isMobile ? 1 : 395, height: isMobile ? 240 : 370 }}
          />
        </div>
      </div>

      <div className="mx-auto  divide-y divide-gray-900/10">
        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
          {data.map(
            (faq, index) =>
              index !== 0 && (
                <Disclosure as="div" key={index} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                          <span className="text-base font-semibold leading-7">{faq.head1}</span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            ) : (
                              <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        {faq.detail && (
                          <p className="text-base leading-7 text-gray-600">{faq.detail}</p>
                        )}
                        {faq.detail2 && (
                          <p className="text-base leading-7 text-gray-600">{faq.detail2}</p>
                        )}
                        {faq.detailMail && mailText}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )
          )}
        </dl>
      </div>
    </Container>
  );
}

const data = [
  {
    id: 1,
    expanded: 'panel1',
    head1: 'Biletinial Forecast nasıl çalışıyor?',
    detail: `Sistem, henüz vizyona girmemiş bir film için katılımcıların izleyici sayısını tahmin etmelerine imkan tanıyan interaktif bir platformdur. Her bir paydaş, kendi sektörel bilgisi ve deneyimine dayalı olarak bir tahmin yapabilir. Yapılan tüm tahminler toplanıp yapay zeka ile optimize ediliyor ve bu sayede biletinial gişe tahmini ortaya çıkıyor.
      Filmin gerçek gişesine en yakın tahmini yapanlar ise liderlik tablosunda yerini alırken, çeşitli ödüllerin sahibi oluyorlar.
      `,
    detail2: `Filmin gerçek gişesine en yakın tahmini yapanlar ise liderlik tablosunda yerini alırken, çeşitli ödüllerin sahibi oluyorlar.`,
  },
  {
    id: 2,
    expanded: 'panel2',
    head1: 'Tahminde bulunmayanlar ne yapabilir?',
    detail:
      'Sistem, katılımcıların diğer katılımcıların tahminlerini görmesini engeller. Bununla birlikte, bu katılımcılar sistemdeki genel performansı takip edebilir, sektörel trendleri analiz edebilir ve gelecekteki tahmin etkinliklerine katılmak için hazırlık yapabilirler.',
  },
  {
    id: 3,
    expanded: 'panel3',
    head1: 'Hangi tarihler arasında tahmin yapılabilir?',
    detail:
      'Tahminler, filmlerin sisteme tanımlanmasından sonra vizyon tarihine kadar yapılmalıdır.',
  },
  {
    id: 4,
    expanded: 'panel4',
    head1: 'Tahminlerin doğruluğu nasıl kontrol edilecek?',
    detail:
      'Gerçek izleyici sayıları, film gösteriminden sonra toplanacak ve sisteme kaydedilecektir. Bu veriler, sistemdeki tahminlerle karşılaştırılacak ve her katılımcının doğruluk oranı belirlenecektir.',
  },
  {
    id: 5,
    expanded: 'panel5',
    head1: 'Puanlama nasıl yapılacak ve ne zaman duyurulacak?',
    detail:
      'Puanlama, film gösteriminden sonra gerçekleşen gişe satışlarıyla en yakın tahmini yapan katılımcılara verilecektir. Puanlar, sonuçların belirlenmesinden kısa bir süre sonra katılımcılara duyurulacak ve genel sıralamalar paylaşılacaktır.',
  },
  {
    id: 6,
    expanded: 'panel6',
    head1: 'Her katılımcının tahmini ne kadar etkili olacak?',
    detail:
      'Her katılımcının tahmini, tahminlerinin doğruluklarına göre yapay zeka tarafından hesaplanan farklı ağırlıklarla değerlendirilecektir. Bu, her bir katılımcının katkısını adaletli bir şekilde değerlendirmemizi sağlayacaktır.',
  },
  {
    id: 7,
    expanded: 'panel7',
    head1: 'Sisteme katılanlar hangi verilere erişebilecek?',
    detail:
      'Katılımcılar, kendi tahminleri ile ilgili performanslarını ve genel sektör istatistiklerini görebileceklerdir. Ayrıca, sistemdeki diğer katılımcıların genel performansına ilişkin özet bilgilere, paydaşların sektördeki pozisyonlarına göre hesaplanan analizlere de erişim sağlayabileceklerdir.',
  },
  {
    id: 8,
    expanded: 'panel8',
    head1: 'Sisteme öneri ve geri bildirimde bulunma imkanımız var mı?',
    detailMail: true,
  },

  {
    id: 9,
    expanded: 'panel9',
    head1: 'Sistemde herhangi bir ödül veya tanıtım imkanı var mı?',
    detail:
      'Evet, en yakın tahmini yapan katılımcılara daha sonra duyurulacak çeşitli ödüller verilecektir. Ayrıca, başarılı katılımcılar sektördeki başarılarını göstermek için bu platformu etkili bir tanıtım aracı olarak kullanabileceklerdir.',
  },
];
