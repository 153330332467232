import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';

import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function ConfirmDialog({isImg, title, content, action, open, onClose, ...other }) {
  
  return (
    <Dialog fullWidth maxWidth={isImg ? "sm" : "xs"} open={open} onClose={onClose} {...other}>
      <Stack spacing={2} p={3}>
      <Grid container spacing={0} >
        <Grid item xs={2}>
          <Box sx={{display: "flex",alignItems:"center", borderRadius:"100%",justifyContent:"start", }}>
            <Avatar variant='square' src= {isImg ?? "/assets/extra/alert_dialog.svg"} sx={{width: isImg && 64, height:  isImg && 95, borderRadius: isImg && 1}}  />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Stack spacing={1}>
            <Typography variant="subtitle1" color="initial">
              {title}
            </Typography>
            <Typography variant="body2" className='text-zinc-500'>
              {content || ''}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <DialogActions sx={{p:0}}>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Vazgeç
        </Button>

        {action}
      </DialogActions>
      </Stack>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  isImg: PropTypes.string,
};
