import React from 'react';
import { Helmet } from 'react-helmet-async';
import { RenderDataProvider } from 'src/sections/profile/context/context';
import ProfileWiew from 'src/sections/profile/view/view';

export default function MyProfile() {
  return (
    <>
      <Helmet>
        <title>Profil</title>
      </Helmet>

      <RenderDataProvider>
        <ProfileWiew />
      </RenderDataProvider>
    </>
  );
}
