import React from 'react';

export default function LoadingSection() {
  return (
    <div className="w-full h-96 flex justify-center gap-3 items-center">
      <figure>
        <img src="/assets/extra/icons8-spinner.gif" alt="spiner" />
      </figure>
      <h4 className="text-2xl font-semibold flex justify-center">Yükleniyor...</h4>
    </div>
  );
}
