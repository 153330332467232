/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
// import { Avatar, Button, Dialog } from '@mui/material';
import { Avatar, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NumericFormat } from 'react-number-format';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';

import { WebServices } from 'src/services/requests';
import { fNumber } from 'src/utils/format-number';
import { fDate } from 'src/utils/format-time';
import Iconify from 'src/components/iconify';
import { useRenderData } from '../context/context';
import ListItemDetails from './list-item-details';

export default function ListItem({ item, changeFilter }) {
  const { enqueueSnackbar } = useSnackbar();

  const [collapseOpen, setCollapseOpen] = useState(false);

  const { setResDataAll, fetchAllData } = useRenderData();

  const [sendData, setSendData] = useState('');

  const [isSuccess, setIsSuccess] = useState(false);

  const renderFunc = async () => {
    await setResDataAll(null);
    await fetchAllData();
  };

  useEffect(() => {
    if (isSuccess) {
      renderFunc();
    }
    setSendData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeFilter]);

  const confirm = useBoolean();

  const onSubmit = async () => {
    if (sendData === '') {
      enqueueSnackbar(`${item.movieName} filminin seyirci sayısı boş bırakılamaz.`, {
        variant: 'error',
      });
      return;
    }

    // console.log(sendData);

    const isForm = false;

    const response = await WebServices.updateAuidience(
      {
        movieId: item.id,
        totalAudience: parseInt(sendData.replace(/,/g, ''), 10),
      },
      isForm
    );
    confirm.onFalse();
    // console.log('create category = ', response);
    if (response.success) {
      enqueueSnackbar('İşlem Başarılı');
      setIsSuccess(true);
      // console.log("dönen başarı register : ",response);
      // reset();
    } else {
      enqueueSnackbar('İşlem Başarısız', {
        variant: 'error',
      });
    }
  };

  // const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <>
      <div className="w-full  border border-solid mb-3 border-[#DFDFDF] rounded-lg max-md:w-full max-lg:w-[calc(50%-10px)] shadow-md p-5 max-md:p-4  max-md:mb-0">
        <div className="flex items-center justify-between max-lg:flex-col  max-lg:gap-5">
          <div className="flex gap-5 items-center max-w-[400px] min-w-[400px] max-xl:max-w-[300px] max-xl:min-w-[300px] max-lg:max-w-full max-lg:min-w-full">
            {/* <div>
              <Switch {...label} />
            </div> */}
            <Avatar variant="square" className="w-16 h-20 rounded" src={item.imageUrl} alt="Film" />
            <div>
              <p className="text-lg font-semibold line-clamp-2 max-lg:text-base">
                {item.movieName}
              </p>
              <p className="text-sm font-bold text-gray-500 max-lg:text-xs">
                Satışa Kapanma Tarihi:{' '}
                <span className="font-medium">{fDate(item.releaseDate)}</span>
              </p>
            </div>
          </div>
          <div className="flex flex-col items-end max-lg:items-start max-w-[170px] min-w-[170px] max-xl:max-w-[100px] max-xl:min-w-[100px] max-lg:max-w-full max-lg:min-w-full ">
            <p className="text-sm text-gray-500 max-lg:text-xs">Seyirci Sayısı</p>
            <p className="font-bold text-2xl max-lg:text-lg">
              {fNumber(isSuccess ? parseInt(sendData.replace(/,/g, ''), 10) : item.totalAudience)}
            </p>
          </div>
          {item.isWaitingMovie ? (
            !isSuccess ? (
              <div className="flex items-center gap-5 max-w-[580px] min-w-[580px] max-xl:max-w-[400px] max-xl:min-w-[400px] max-lg:max-w-full  max-lg:flex-col max-lg:min-w-full">
                <NumericFormat
                  className="px-4 h-14 text-lg border border-solid rounded-lg max-w-[320px] min-w-[320px] max-xl:max-w-[200px]  max-xl:min-w-[200px] max-lg:max-w-full max-lg:min-w-full max-xl:placeholder:text-xs max-xl:h-10"
                  value={sendData}
                  disabled
                  allowLeadingZeros
                  allowNegative={false}
                  thousandSeparator=","
                  maxLength={10}
                  onChange={(event) => {
                    setSendData(event.target.value);
                  }}
                />

                <Button
                  disabled
                  onClick={() => confirm.onTrue()}
                  // className="bg-[#F8E71C] text-black font-medium rounded-lg h-14 px-7 text-center max-xl:whitespace-nowrap max-xl:h-10 max-xl:text-xs max-lg:w-full"
                  className="bg-[#CCCCCC] text-black font-medium rounded-lg h-14 px-7 text-center max-xl:whitespace-nowrap max-xl:h-10 max-xl:text-xs max-lg:w-full"
                >
                  Kaydet & Skor Hesaplama
                </Button>
              </div>
            ) : (
              <div className="flex items-center gap-5 max-w-[580px] min-w-[580px] justify-end">
                <Avatar variant="circular" src="/assets/extra/onay.svg" alt="Film" />
                <div className="flex flex-col items-start">
                  <p className="text-sm text-gray-500">Seyirci Sayısı Kaydedildi</p>
                </div>
              </div>
            )
          ) : isSuccess ? (
            <div className="flex items-center gap-5 max-w-[580px] min-w-[580px] justify-end">
              <Avatar variant="circular" src="/assets/extra/onay.svg" alt="Film" />
              <div className="flex flex-col items-start">
                <p className="text-sm text-gray-500">Seyirci Sayısı Kaydedildi</p>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-5 max-w-[580px] min-w-[580px] max-xl:max-w-[400px] max-xl:min-w-[400px] max-lg:max-w-full max-lg:min-w-full max-lg:flex-col justify-end">
              <NumericFormat
                className="px-4 h-14 text-lg border border-solid rounded-lg max-w-[320px] min-w-[320px] max-xl:max-w-[200px]  max-xl:min-w-[200px] max-lg:max-w-full max-lg:min-w-full max-xl:placeholder:text-xs max-xl:h-10"
                value={sendData}
                disabled
                allowLeadingZeros
                allowNegative={false}
                thousandSeparator=","
                maxLength={10}
                onChange={(event) => {
                  setSendData(event.target.value);
                }}
              />
              <Button
                disabled
                onClick={() => confirm.onTrue()}
                // className="bg-[#F8E71C] text-black font-medium rounded-lg h-14 px-7 text-center max-lg:w-full max-lg:h-10"
                className="bg-[#CCCCCC] text-black font-medium rounded-lg h-14 px-7 text-center max-lg:w-full max-lg:h-10"
              >
                Güncelle
              </Button>
            </div>
          )}
          {!item.isWaitingMovie && (
            <Button
              sx={{ borderRadius: '30%' }}
              onClick={async () => {
                await setCollapseOpen(!collapseOpen);
              }}
            >
              <Iconify icon={collapseOpen ? 'ph:arrow-up' : 'ph:arrow-down'} />
            </Button>
          )}
        </div>

        {collapseOpen && <ListItemDetails movieId={item.id} />}
      </div>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Skoru Onaylıyor Musunuz?"
        content={
          item?.isWaitingMovie
            ? `Girmiş olduğunuz ${sendData} skoru kaydedilecektir. Daha sonra güncellemek isterseniz "Tahmini Tamamlanan Filmler" den güncelleyebilirsiniz.`
            : `Girmiş olduğunuz ${sendData} ile güncellenecektir. İsterseniz tekrar değiştirebilirsiniz.`
        }
        action={
          <Button
            className="px-8"
            variant="contained"
            color="success"
            onClick={() => {
              confirm.onFalse();
              onSubmit();
            }}
          >
            Kaydet
          </Button>
        }
      />
    </>
  );
}
