/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import {
  Avatar,
  Box,
  Button,
  Slider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { renderErrorMessages } from 'src/utils/formatErrorMessages';
import { WebServices } from 'src/services/requests';
import Iconify from 'src/components/iconify';
import { fNumber } from 'src/utils/format-number';
import { useBoolean } from 'src/hooks/use-boolean';
import { ConfirmDialog } from 'src/components/custom-dialog';
// import { fDate } from 'src/utils/format-time';
// import { useRenderData } from '../context/context';

export default function Add({ item, handleCloseDialog, setIsSuccessRes, date }) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  // console.log(item);
  //   const { setResDataAll, fetchAllData } = useRenderData();

  const confirm = useBoolean();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [foreCastValue, setForeCastValue] = useState(50000);

  const forecastOnSubmit = async () => {
    const isForm = false;
    const data = {
      movieId: item.movieId,
      audience: foreCastValue,
    };

    const response = await WebServices.createForecast(data, isForm);
    if (response.success) {
      await enqueueSnackbar('İşlem Başarılı');
      //   await handleCloseDialog();
      await new Promise((resolve) => setTimeout(resolve, 200));
      await setIsSuccessRes(true);
      confirm.onFalse();
      //   await setResDataAll(null);
      //   await fetchAllData();
    } else {
      enqueueSnackbar(renderErrorMessages(response.response), {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Stack
        spacing={isMobile ? 1 : 2}
        py={isMobile ? 2 : 5}
        px={isMobile ? 3 : 6}
        sx={{
          maxWidth: 600,
          minWidth: 600,
          [theme.breakpoints.down('md')]: {
            maxWidth: 1,
            minWidth: 1,
          },
        }}
      >
        <div className="flex items-center gap-7 max-md:gap-4">
          <Avatar
            className="w-24 rounded-md h-auto min-h-[130px] max-md:w-16 max-md:max-h-[90px] max-md:min-h-[90px] object-cover"
            variant="square"
            src={item?.imageUrl}
          />
          <Box>
            <Typography
              className="text-[#0071E3] max-w-xs max-md:max-w-full max-md:text-lg max-md:leading-5  line-clamp-3 overflow-hidden"
              variant="h4"
            >
              {item?.movieName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.5)' }}>
              <span className="max-md:text-xs" style={{ fontWeight: '600' }}>
                Vizyon Tarihi :
              </span>{' '}
              {date}
            </Typography>
            {/* <Typography className="font-semibold text-2xl" variant="body1">
      Tahminde Bulun
    </Typography> */}
          </Box>
        </div>

        <Box maxWidth={isMobile ? 1 : 0.7}>
          <Typography variant={isMobile ? 'subtitle1' : 'h4'} color="initial">
            {item?.movieName} filmini kaç kişi izler?
          </Typography>
          <Typography variant={isMobile ? 'caption' : 'body2'}>
            Tahminde bulunmak için yeşil ikonu sürükleyin.
          </Typography>
        </Box>

        <Box
          sx={{
            width: 1,
            [theme.breakpoints.down('md')]: {
              px: 1,
            },
          }}
        >
          <Slider
            className="text-green-600"
            aria-label="Temperature"
            valueLabelFormat={fNumber(foreCastValue)}
            value={foreCastValue}
            valueLabelDisplay="auto"
            onChange={(event, newValue) => {
              setForeCastValue(newValue);
            }}
            // step={50000}
            step={
              foreCastValue < 2000000
                ? 100000
                : foreCastValue < 3000000
                ? 200000
                : foreCastValue < 4000000
                ? 300000
                : 500000
            }
            marks
            min={50000}
            max={10000000}
            sx={{
              '& .MuiSlider-thumb': {
                width: isMobile ? 15 : 25,
                height: isMobile ? 15 : 25, // İstediğiniz yüksekliği ayarlayabilirsiniz
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* <Typography variant="h4"></Typography> */}
          <Button
            className="w-full flex justify-between px-10 h-14 max-md:px-5 max-md:h-12 max-md:w-full bg-[#0071E3]"
            variant="contained"
            // onClick={forecastOnSubmit}
            onClick={() => confirm.onTrue()}
          >
            {fNumber(foreCastValue)}{' '}
            <span className="flex gap-2 items-center">
              Tahmini Onayla <Iconify icon="ph:arrow-right" />
            </span>
          </Button>
        </Box>
      </Stack>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Skoru Onaylıyor Musunuz?"
        content={`${item?.movieName} filmine girmiş olduğunuz ${fNumber(
          foreCastValue
        )} seyirci sayısı tahminini onaylıyor musunuz?`}
        action={
          <Button
            className="px-8"
            variant="contained"
            color="success"
            onClick={() => {
              forecastOnSubmit();
            }}
          >
            Kaydet
          </Button>
        }
      />
    </>
  );
}
