/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import React from 'react';

import { Button } from '@mui/material';
// import { fDate } from 'src/utils/format-time';
import _ from 'lodash';
import EmptyData from 'src/components/empty/emptyData';
import ListItem from './list-item';

export default function ForecestList({
  resDataAll,
  setChangeFilter,
  waitingFilterData,
  changeFilter,
}) {
  const isWaitingMovieCount = _.filter(resDataAll, { isWaitingMovie: true }).length;

  return (
    <div className="flex flex-col gap-16 -mt-8 max-xl:mt-5 max-md:-m-0 max-md:mt-5 max-lg:gap-5">
      <div className="flex gap-3">
        <Button
          className={
            changeFilter
              ? 'py-3 px-8 text-[#0071E3] border border-solid border-[#0071E3] max-md:text-xs max-md:py-2 max-md:px-4 max-md:text-start'
              : 'py-3 px-8 text-black border border-solid border-black max-md:text-xs max-md:py-2 max-md:px-4 max-md:text-start'
          }
          disabled={isWaitingMovieCount === 0}
          onClick={() => setChangeFilter(true)}
        >
          Tahmin Edilmek İçin Bekleyen Filmler{' '}
          <span
            className={
              changeFilter
                ? 'text-xs font-bold bg-[#0071E3] py-1 px-2 ml-2 text-white rounded'
                : 'text-xs font-bold bg-black py-1 px-2 ml-2 text-white rounded'
            }
          >
            {isWaitingMovieCount}
          </span>
        </Button>
        <Button
          className={
            !changeFilter
              ? 'py-3 px-8 text-[#0071E3] border border-solid border-[#0071E3] max-md:text-xs max-md:py-2 max-md:px-4 max-md:text-start'
              : 'py-3 px-8 text-black border border-solid border-black max-md:text-xs max-md:py-2 max-md:px-4 max-md:text-start'
          }
          disabled={resDataAll.length - isWaitingMovieCount === 0}
          onClick={() => setChangeFilter(false)}
        >
          Tahmini Tamamlanan Filmler{' '}
          <span
            className={
              !changeFilter
                ? 'text-xs font-bold bg-[#0071E3] py-1 px-2 ml-2 text-white rounded'
                : 'text-xs font-bold bg-black py-1 px-2 ml-2 text-white rounded'
            }
          >
            {resDataAll.length - isWaitingMovieCount}
          </span>
        </Button>
      </div>

      <div>
        <div className="max-lg:flex flex flex-col max-lg:flex-wrap gap-5 max-lg:flex-row max-lg:items-start">
          {waitingFilterData.length === 0 ? (
            <EmptyData />
          ) : (
            waitingFilterData.map((item, index) => (
              <ListItem item={item} key={index} changeFilter={changeFilter} />
            ))
          )}
        </div>
      </div>
    </div>
  );
}
