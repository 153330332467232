/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { WebServices } from 'src/services/requests';

const RenderDataContext = createContext();

export function useRenderData() {
  return useContext(RenderDataContext);
}

export function RenderDataProvider({ children }) {
  const [resDataAll, setResDataAll] = useState(null);
  const [resDataTopList, setResDataTopList] = useState(null);

  async function fetchAllData() {
    const { data } = await WebServices.getAllUpcomingMovies();
    // const { data } = await WebServices.getAllCompletedFilmsForAdmin();
    setResDataAll(formattedDataFunc(data?.data));
    // setResDataAll(_tempData);
  }

  async function fetchTopData() {
    const { data } = await WebServices.getAllTopForastMovies();
    // const { data } = await WebServices.getAllCompletedFilmsForAdmin();
    setResDataTopList(data?.data);
    // setResDataAll(_tempData);
  }

  useEffect(() => {
    fetchAllData();
    fetchTopData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedDataFunc = (data) => {
    const formattedData = data?.map((item) => ({
      releaseDate: item.releaseDate,
      upComingMovies: item.upComingMovies.filter((movie) => !movie.isInVision),
    }));

    return formattedData || [];
  };

  const value = {
    resDataAll,
    setResDataAll,
    fetchAllData,
    resDataTopList,
    setResDataTopList,
    fetchTopData,
  };

  return <RenderDataContext.Provider value={value}>{children}</RenderDataContext.Provider>;
}
