import { Helmet } from 'react-helmet-async';
import  View from 'src/sections/my-forecastes/view/view';
import { RenderDataProvider } from 'src/sections/my-forecastes/context/context';

// sections

// ----------------------------------------------------------------------

export default function Page() {
  return (
    <>
      <Helmet>
        <title>Tahminlerim</title>
      </Helmet>

      <RenderDataProvider>
        <View />
      </RenderDataProvider>
    </>
  );
}
