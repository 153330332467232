/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/button-has-type */
// @mui
import Container from '@mui/material/Container';
// components

import { Button, Avatar } from '@mui/material';
import { useState } from 'react';
import EmptyData from 'src/components/empty/emptyData';
import ErrorSection from 'src/components/error/error';
import LoadingSection from 'src/components/loading-screen/loading-section';
import UserListView from '../list/list';
import { useRenderData } from '../context/context';
import AddUser from '../add/add';

// ----------------------------------------------------------------------

export default function UsersWiew() {
  const { resDataAll } = useRenderData();

  const [isCreateUserPage, setIsCreateUserPage] = useState(false);

  // console.log(resDataAll);

  return (
    <Container maxWidth="xl">
      <div className="flex gap-2 mb-10 justify-between items-center max-sm:flex-col max-sm:items-start max-sm:gap-5">
        {isCreateUserPage ? (
          <div>
            <h1 className="text-3xl font-semibold">Yeni Kullanıcı Oluştur</h1>
            <p className="opacity-50 text-black">
              Yeni Kullanıcı Oluşturmak İçin Lütfen Gerekli Alanları Doldurunuz.
            </p>
          </div>
        ) : (
          <div>
            <h1 className="text-3xl font-semibold">Kullanıcılar</h1>
            <p className="opacity-50 text-black">
              Sisteme kayıtlı kullanıcıları bu kısımdan onaylayabilir, reddedebilirsiniz.
            </p>
          </div>
        )}

        <div className="flex justify-end">
          {!isCreateUserPage ? (
            <Button
              onClick={() => setIsCreateUserPage(true)}
              className="py-3 px-8 text-[#007B55] border border-solid border-[#007B55] flex items-center gap-2"
            >
              Yeni Ekle
              <Avatar
                variant="square"
                src="/assets/extra/ekle.svg"
                alt=""
                sx={{ width: 24, height: 24, display: 'flex', alignItems: 'center' }}
              />{' '}
            </Button>
          ) : (
            <Button
              onClick={() => setIsCreateUserPage(false)}
              className="py-3 px-8 text-[#0f0f0f] border border-solid border-[#0f0f0f] flex items-center gap-2"
            >
              Listeye Dön
              <Avatar
                variant="square"
                src="/assets/extra/left-arrow.svg"
                alt=""
                sx={{ width: 16, height: 16, display: 'flex', alignItems: 'center' }}
              />{' '}
            </Button>
          )}
        </div>
      </div>

      {isCreateUserPage ? (
        <AddUser setIsCreateUserPage={setIsCreateUserPage} />
      ) : resDataAll && resDataAll?.length === 0 ? (
        <EmptyData />
      ) : resDataAll ? (
        <UserListView />
      ) : resDataAll === null ? (
        <LoadingSection />
      ) : (
        <ErrorSection
          errorTexts={{
            mainMsg: 'Sisteme Kayıtlı Kullanıcılar Yüklenemedi',
          }}
        />
      )}
    </Container>
  );
}
