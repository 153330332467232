/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import React from 'react';

// import _ from 'lodash';
import { fDate } from 'src/utils/format-time';
import ListItem from './list';

export default function ForecestTopList({ resDataAll }) {
  return (
    <div className="flex flex-col gap-2 -mt-8 max-md:-m-0 max-md:mt-4 max-md:gap-4">
      {resDataAll?.map((item, index) => (
        <ListItem key={index} movieIndex={index} item={item} date={fDate(item.releaseDate)} />
      ))}
    </div>
  );
}
