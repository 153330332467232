/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Avatar, Button, useTheme, useMediaQuery } from '@mui/material';
import Iconify from 'src/components/iconify';
import { fNumber } from 'src/utils/format-number';
import { fDate } from 'src/utils/format-time';
import ListItemDetails from './list-item-details';

export default function ListItem({ item, index, date }) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const showImagesCount = isMobile ? 3 : 5;

  return (
    <div
      key={index}
      className="w-full  border border-solid mb-3 border-[#DFDFDF] rounded-lg max-md:w-full max-lg:w-[calc(50%-10px)] shadow-md p-5 max-md:p-4 max-md:mb-0 "
    >
      <div className="flex items-center justify-between max-lg:flex-col  max-lg:gap-5">
        <div className="flex gap-5 items-center  min-w-[350px] max-w-[350px] max-xl:min-w-[250px] max-xl:max-w-[250px] max-2xl:min-w-fit max-lg:w-full max-lg:max-w-full max-lg:border-solid max-lg:border-b max-lg:border-gray-200 max-lg:pb-3">
          <Avatar src={item?.imageUrl} className="w-16 h-20 rounded-lg" alt={item?.movieName} />
          <div>
            <h2 className="text-lg text-black font-semibold max-w-xs overflow-hidden line-clamp-2 max-lg:text-base max-xl:text-sm">
              {item?.movieName}
            </h2>
            <p className="text-gray-500 font-medium text-sm max-lg:text-xs max-xl:text-[10px] max-xl:leading-3">
              Vizyon Tarihi: {fDate(date)}
            </p>
          </div>
        </div>
        <div className="flex gap-10 max-lg:w-full max-lg:items-start min-w-[320px] max-w-[320px] max-lg:max-w-full  max-md:min-w-full items-center  max-lg:gap-5 max-lg:border-solid max-lg:border-b max-lg:border-gray-200 max-lg:pb-3">
          <div className="flex flex-col items-end max-lg:items-start">
            <h3 className="opacity-50 text-sm whitespace-nowrap max-xl:text-xs">Tahmin Sayısı</h3>
            <div className="text-2xl font-bold max-lg:text-lg max-xl:text-base">
              {item?.totalEstimators}
            </div>
          </div>

          <div className="flex min-w-[230px] max-w-[350px] max-2xl:min-w-fit max-lg:w-full max-md:min-w-[100px] max-lg:justify-end max-md:max-w-full">
            <div className="isolate flex -space-x-3 overflow-hidden">
              {item?.estimators?.slice(0, showImagesCount).map((imgItem, i) => (
                <Avatar
                  className="w-12 h-12 border-4 border-solid border-white shadow-sm bg-white"
                  key={i}
                  src={imgItem}
                  width={46}
                  height={46}
                  alt="person"
                />
              ))}

              {item?.totalEstimators - showImagesCount > 0 && (
                <div
                  className="relative border-2 border-solid border-gray-100  h-12 w-12 rounded-full bg-gray-400 text-white text-center flex justify-center items-center text-xs font-semibold"
                  width={46}
                  height={46}
                >
                  +{item?.totalEstimators - showImagesCount}
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className={` flex gap-6 max-2xl:gap-4 justify-end  max-lg:justify-start min-w-[500px] max-w-[500px] max-2xl:min-w-fit max-lg:min-w-full max-lg:w-full max-lg:flex-col `}
        >
          <div className="flex flex-col items-end max-lg:items-start max-lg:border-solid min-w-[150px] max-w-[150px] max-2xl:min-w-fit max-lg:min-w-full max-lg:w-full max-lg:border-b max-lg:border-gray-200 max-lg:pb-3">
            <p className="opacity-50 text-sm max-xl:text-xs">Genel Seyirci Tahmini</p>
            <div className="text-2xl font-bold max-lg:text-lg max-xl:text-base">
              {fNumber(item?.avarageEstimate)}
            </div>
          </div>
          <div className="flex flex-col items-end max-lg:items-start min-w-[150px] max-w-[150px] max-2xl:min-w-fit max-lg:min-w-full max-lg:w-full ">
            <p className="text-gray-500 text-sm max-xl:text-xs">Tahmininiz</p>
            <div className="text-2xl font-bold max-lg:text-lg max-xl:text-base">
              {fNumber(item?.userEstimate)}
            </div>
          </div>

          <div className="w-full flex justify-center items-center">
            <Button
              sx={{ borderRadius: '30%' }}
              onClick={async () => {
                await setSelectedIndex(index);
                await setCollapseOpen(!collapseOpen);
              }}
            >
              <Iconify
                icon={collapseOpen && selectedIndex === index ? 'ph:arrow-up' : 'ph:arrow-down'}
              />
            </Button>
          </div>
        </div>
      </div>
      {collapseOpen && selectedIndex === index && <ListItemDetails movieId={item.movieId} />}
    </div>
  );
}
