/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
// import { Avatar } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import { WebServices } from 'src/services/requests';
import { Skeleton, Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import { fNumber } from 'src/utils/format-number';
import { WebServices } from 'src/services/requests';
import EmptyData from 'src/components/empty/emptyData';
import ChartRadialBar from 'src/components/chart/chart-view/chart-radial-bar';
// import ListItemDetailsChart from './list-item-details-chart';

export default function ListItemDetails({ movieId }) {
  const [resData, setResData] = useState(null);
  async function fetchDropDownData() {
    const { data } = await WebServices.getMovieEstimateDetails({ MovieId: movieId });
    setResData(data?.data);
  }

  useEffect(() => {
    fetchDropDownData({ MovieId: movieId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {resData ? (
        <div className="container  mx-auto py-2 mt-2">
          <div>
            <div className="w-full  flex justify-between pt-5  border-t border-solid border-gray-200 p-5 max-md:p-0 max-md:pt-2 max-lg:flex-col ">
              <div className="flex-1 max-lg:py-5  ">
                <h2 className="text-xl font-semibold mb-5 text-black">Seyirci Tahmininiz</h2>
                <div className="flex max-lg:flex-col max-md:flex-row max-md:justify-between max-md:items-center max-sm:flex-col max-sm:-mt-0 max-lg:-mt-0 max-sm:items-start max-md:-mt-16 items-start">
                  <div className="flex flex-col gap-7 max-lg:gap-2">
                    <div className="flex flex-col gap-1 max-lg:gap-0">
                      <span className="text-sm  text-gray-500">Tahmininiz</span>
                      <div className="text-2xl max-lg:text-lg font-bold ">
                        {fNumber(resData?.movieStats?.userEstimate)}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 max-lg:gap-0">
                      <span className="text-sm text-gray-500">Genel Tahmin Ortalaması</span>
                      <div className="text-2xl  max-lg:text-lg font-bold ">
                        {fNumber(resData?.movieStats?.movieAvarage)}
                      </div>
                    </div>

                    <div className="flex flex-col gap-1 max-lg:gap-0">
                      <span className="text-sm text-gray-500">Genel Tahmine Oranınız</span>
                      <div className="text-2xl  max-lg:text-lg font-bold ">
                        {fNumber(resData?.movieStats?.userRate)}%
                      </div>
                    </div>
                  </div>

                  <ChartRadialBar
                    series={[resData?.movieStats?.userRate || 0]}
                    labels={['Genel Tahmin Ortalamasına Göre']}
                    value={resData?.movieStats?.userRate || 0}
                  />
                </div>
              </div>
              <div className="flex-1 max-lg:py-5 ">
                <h2 className="text-xl font-semibold mb-5 text-black">Sektörel Tahminler</h2>
                <div className="flex flex-col gap-7 max-lg:gap-4">
                  {resData?.userTypeStats.length === 0
                    ? emptyData.map((user, index) => (
                        <div key={index} className="flex flex-col gap-1 max-lg:gap-0">
                          <span className="text-sm text-gray-500">{user?.userTypes}</span>
                          <div className="text-base max-lg:text-sm font-bold ">
                            Henüz Hesaplanmadı
                          </div>
                        </div>
                      ))
                    : resData?.userTypeStats?.map((user, index) => (
                        <div key={index} className="flex flex-col gap-1 max-lg:gap-0">
                          <span className="text-sm text-gray-500">{user?.userTypes}</span>
                          <div className="text-2xl max-lg:text-lg font-bold ">
                            {fNumber(user?.userTypeAvarage)}{' '}
                            <span className="text-sm font-normal">
                              / En düşük {fNumber(user?.minEstimate)} En yüksek{' '}
                              {fNumber(user?.maxEstimate)}
                            </span>
                          </div>
                        </div>
                      ))}
                </div>
              </div>

              <div className=" max-lg:py-5">
                <h2 className="text-xl font-semibold mb-5 text-black max-xl:text-lg">
                  Tahmin Liderlik Tablosu
                </h2>
                <div className="flex flex-col w-80  max-lg:w-full">
                  {tempLeaderBoards?.map((item, index) => (
                    //   console.log(item);
                    <div
                      className="flex items-center justify-between last-of-type:border-none py-2 border-b border-solid border-gray-200"
                      key={index}
                    >
                      <div className="flex items-center">
                        <div
                          className={`min-w-[30px] font-bold text-${
                            item?.isOwnUser && '[#0071E3]'
                          }`}
                        >
                          {item?.rank}
                        </div>

                        <Avatar
                          className=" mr-3"
                          src={item?.profilePhotos}
                          width={40}
                          height={40}
                          alt={item?.userNameSurname}
                        />
                        <p
                          className={`font-semibold text-base text-${
                            item?.isOwnUser && '[#0071E3]'
                          }`}
                        >
                          {item?.isOwnUser ? 'Siz' : item?.userNameSurname}
                        </p>
                      </div>
                      {index === 0 && (
                        <Avatar
                          className=" mr-3 w-6 h-6"
                          src="/assets/extra/king.svg"
                          width={24}
                          height={24}
                          alt={item?.userNameSurname}
                        />
                      )}
                      <div />
                      <div className={`font-semibold~ text-${item?.isOwnUser && '[#0071E3]'}`}>
                        {item?.score}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : resData?.estimateStats === null ? (
        <EmptyData text="Bu film için henüz analiz yapılmadı" small />
      ) : (
        <Skeleton variant="text" width="100%" height={340} animation="pulse" />
      )}
    </>
  );
}

const emptyData = [
  {
    userTypes: 'Sinema İşletmecisi Tahmin Ortalaması',
  },
  {
    userTypes: 'Dağıtımcı Tahmin Ortalaması',
  },
  {
    userTypes: 'Yapımcı Ortalaması',
  },
];

const tempLeaderBoards = [
  {
    rank: 1,
    userNameSurname: 'Kullanıcı 1',
    profilePhotos: '/assets/extra/user(temp).svg',
    score: '-',
    isOwnUser: false,
  },
  {
    rank: 2,
    userNameSurname: 'Kullanıcı 2',
    profilePhotos: '/assets/extra/user(temp).svg',
    score: '-',
    isOwnUser: false,
  },
  {
    rank: 3,
    userNameSurname: 'Kullanıcı 3',
    profilePhotos: '/assets/extra/user(temp).svg',
    score: '-',
    isOwnUser: false,
  },
];
