/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { Avatar, TextField } from '@mui/material';
import { useSettingsContext } from 'src/components/settings';

import ErrorSection from 'src/components/error/error';
import LoadingSection from 'src/components/loading-screen/loading-section';
import { useRenderData } from '../context/context';
import MyForecastList from '../list/list';

const MyForecastView = () => {
  const settings = useSettingsContext();
  const { resDataAll } = useRenderData();
  const [searchKeyword, setSearchKeyword] = useState('');

  const [filteredData, setFilteredData] = useState(null);

  // console.log(filteredData);

  const filterDataFunc = () => {
    if (resDataAll) {
      const filteredInEstimate = resDataAll.inEstimate.map((item) => ({
        ...item,
        inEstimates: item.inEstimates.filter((estimate) =>
          estimate.movieName
            .toLowerCase()
            .includes(searchKeyword.replace(/I/g, 'i').replace(/İ/g, 'i').toLowerCase())
        ),
      }));

      const filteredEstimatedHistory = resDataAll.estimatedHistory.map((item) => ({
        ...item,
        estimatedHistories: item.estimatedHistories.filter((estimate) =>
          estimate.movieName
            .toLowerCase()
            .includes(searchKeyword.replace(/I/g, 'i').replace(/İ/g, 'i').toLowerCase())
        ),
      }));

      setFilteredData({
        inEstimate: filteredInEstimate,
        estimatedHistory: filteredEstimatedHistory,
      });
    }
  };

  // Call the filterData function when the searchKeyword changes
  useEffect(() => {
    filterDataFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, resDataAll]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <div className="container mx-auto">
        <div className="flex flex-col gap-2 mb-10 max-md:mb-4 max-md:gap-0">
          <h1 className="text-3xl font-semibold max-md:text-lg">Tahminleriniz</h1>
          <p className="opacity-50 text-black max-md:text-xs">
            Tahminde bulunduğunuz filmleri bu kısımda görebilirsiniz.
          </p>
        </div>

        <div className="flex justify-between  items-center  max-md:w-full">
          <div />
          <div className="flex gap-3 py-0 relative w-96 items-center rounded-lg  max-md:w-full">
            <Avatar
              variant="circular"
              className="absolute right-5"
              src="/assets/extra/search.svg"
              alt=""
              sx={{ width: 20, height: 20, display: 'flex', alignItems: 'center' }}
            />
            <TextField
              className="w-full"
              label="Film Arama"
              value={searchKeyword}
              InputProps={{
                style: { paddingRight: '36px' },
              }}
              onChange={(event) => {
                setSearchKeyword(event.target.value);
              }}
            />
          </div>
        </div>

        {resDataAll ? (
          <MyForecastList resDataAll={filteredData} />
        ) : resDataAll === null ? (
          <LoadingSection />
        ) : (
          <ErrorSection
            errorTexts={{
              mainMsg: 'Tahminde Bulunduğunuz Filmler Yüklenemedi',
            }}
          />
        )}
      </div>
    </Container>
  );
};

// const resDataAll = {
//   inEstimate: [
//     {
//       releaseDate: '2023-10-11T00:00:00',
//       inEstimates: [
//         {
//           movieId: 326,
//           movieName: "Five Nights at Freddy's",
//           imageUrl:
//             'https://b6s54eznn8xq.merlincdn.net/Anket/Movie/2528b13036454990951cc59686ba549b.png',
//           genre: 'Korku',
//           cast: null,
//           totalEstimators: '3',
//           estimators: [
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/42ddfe9c4d6c452a87ec21db0c507b06.jpeg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/f32bdaed7f3a4ecc954008ac463d9663.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/033dfa3a972c44ed8ac324195942d20a.jpg',
//           ],
//           userEstimate: 1950000,
//           userSuccessRate: 0,
//           avarageEstimate: 1000000,
//         },
//         {
//           movieId: 326,
//           movieName: "Kutsal Damacana",
//           imageUrl:
//             'https://b6s54eznn8xq.merlincdn.net/Anket/Movie/2528b13036454990951cc59686ba549b.png',
//           genre: 'Korku',
//           cast: null,
//           totalEstimators: '3',
//           estimators: [
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/42ddfe9c4d6c452a87ec21db0c507b06.jpeg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/f32bdaed7f3a4ecc954008ac463d9663.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/033dfa3a972c44ed8ac324195942d20a.jpg',
//           ],
//           userEstimate: 1950000,
//           userSuccessRate: 0,
//           avarageEstimate: 1000000,
//         },
//       ],
//     },
//     {
//       releaseDate: '2023-11-03T00:00:00',
//       inEstimates: [
//         {
//           movieId: 326,
//           movieName: "Happy Hour Times",
//           imageUrl:
//             'https://b6s54eznn8xq.merlincdn.net/Anket/Movie/2528b13036454990951cc59686ba549b.png',
//           genre: 'Korku',
//           cast: null,
//           totalEstimators: '3',
//           estimators: [
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/42ddfe9c4d6c452a87ec21db0c507b06.jpeg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/f32bdaed7f3a4ecc954008ac463d9663.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/033dfa3a972c44ed8ac324195942d20a.jpg',
//           ],
//           userEstimate: 1950000,
//           userSuccessRate: 0,
//           avarageEstimate: 1000000,
//         },
//       ],
//     },
//   ],
//   estimatedHistory: [
//     {
//       releaseDate: '2023-10-27T00:00:00',
//       estimatedHistories: [
//         {
//           movieId: 319,
//           movieName: 'It Lives Inside',
//           imageUrl:
//             'https://b6s54eznn8xq.merlincdn.net/Anket/Movie/1ed86fb56fb6496197b6f38a693cdd36.png',
//           genre: 'Dram, Korku, Gerilim',
//           cast: 'Megan Suri, Neeru Bajwa, Mohana Krishnan, Betty Gabriel',
//           totalEstimators: '5',
//           estimators: [
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/cef107c1cf8e48babd29a5964f2d14b2.jpeg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/0fd81c6a4f3d4407aa4f7c7756458444.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/033dfa3a972c44ed8ac324195942d20a.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/f32bdaed7f3a4ecc954008ac463d9663.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/42ddfe9c4d6c452a87ec21db0c507b06.jpeg',
//           ],
//           userEstimate: 500000,
//           userSuccessRate: 0,
//           avarageEstimate: 1290000,
//         },
//         {
//           movieId: 320,
//           movieName: 'Kimitachi wa dô ikiru ka',
//           imageUrl:
//             'https://b6s54eznn8xq.merlincdn.net/Anket/Movie/18826f2f464a4c69823cbee6e6a3a966.png',
//           genre: 'Animasyon, Macere, Dram',
//           cast: 'Soma Santoki, Masaki Suda, Takuya Kimura, Aimyon',
//           totalEstimators: '2',
//           estimators: [
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/f32bdaed7f3a4ecc954008ac463d9663.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/42ddfe9c4d6c452a87ec21db0c507b06.jpeg',
//           ],
//           userEstimate: 5400000,
//           userSuccessRate: 0,
//           avarageEstimate: 3350000,
//         },
//         {
//           movieId: 323,
//           movieName: 'Atatürk',
//           imageUrl:
//             'https://b6s54eznn8xq.merlincdn.net/Anket/Movie/58fcd99251fa4adaac85e73c2fda5acc.png',
//           genre: 'Dram',
//           cast: 'Adam Campbell, Joshua Sasse, Roxane Duran, Julian Fellowes',
//           totalEstimators: '5',
//           estimators: [
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/0fd81c6a4f3d4407aa4f7c7756458444.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/033dfa3a972c44ed8ac324195942d20a.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/42ddfe9c4d6c452a87ec21db0c507b06.jpeg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/cef107c1cf8e48babd29a5964f2d14b2.jpeg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/f32bdaed7f3a4ecc954008ac463d9663.jpg',
//           ],
//           userEstimate: 5300000,
//           userSuccessRate: 0,
//           avarageEstimate: 2920000,
//         },
//       ],
//     },
//     {
//       releaseDate: '2023-12-27T00:00:00',
//       estimatedHistories: [
//         {
//           movieId: 323,
//           movieName: 'Mustafa Kemal',
//           imageUrl:
//             'https://b6s54eznn8xq.merlincdn.net/Anket/Movie/58fcd99251fa4adaac85e73c2fda5acc.png',
//           genre: 'Dram',
//           cast: 'Adam Campbell, Joshua Sasse, Roxane Duran, Julian Fellowes',
//           totalEstimators: '5',
//           estimators: [
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/0fd81c6a4f3d4407aa4f7c7756458444.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/033dfa3a972c44ed8ac324195942d20a.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/42ddfe9c4d6c452a87ec21db0c507b06.jpeg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/cef107c1cf8e48babd29a5964f2d14b2.jpeg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/f32bdaed7f3a4ecc954008ac463d9663.jpg',
//           ],
//           userEstimate: 5300000,
//           userSuccessRate: 0,
//           avarageEstimate: 2920000,
//         },

//         {
//           movieId: 323,
//           movieName: 'Atatürk',
//           imageUrl:
//             'https://b6s54eznn8xq.merlincdn.net/Anket/Movie/58fcd99251fa4adaac85e73c2fda5acc.png',
//           genre: 'Dram',
//           cast: 'Adam Campbell, Joshua Sasse, Roxane Duran, Julian Fellowes',
//           totalEstimators: '5',
//           estimators: [
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/0fd81c6a4f3d4407aa4f7c7756458444.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/033dfa3a972c44ed8ac324195942d20a.jpg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/42ddfe9c4d6c452a87ec21db0c507b06.jpeg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/cef107c1cf8e48babd29a5964f2d14b2.jpeg',
//             'https://b6s54eznn8xq.merlincdn.net/Anket/User/f32bdaed7f3a4ecc954008ac463d9663.jpg',
//           ],
//           userEstimate: 5300000,
//           userSuccessRate: 0,
//           avarageEstimate: 2920000,
//         },
//       ],
//     },
//   ],
// };

export default MyForecastView;
