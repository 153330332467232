import { Helmet } from 'react-helmet-async';
import { RenderDataProvider } from 'src/sections/manage-films/context/context';
import ManageWiew from 'src/sections/manage-films/view/view';

// ----------------------------------------------------------------------

export default function Page() {
  return (
    <>
      <Helmet>
        <title>Filmleri Yönet</title>
      </Helmet>

      <RenderDataProvider>
        <ManageWiew />
      </RenderDataProvider>
    </>
  );
}
