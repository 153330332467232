/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import InputAdornment from '@mui/material/InputAdornment';
// hooks
import { Grid, IconButton, InputAdornment, MenuItem, Skeleton } from '@mui/material';

// import { useBoolean } from 'src/hooks/use-boolean';

// components
// import Iconify from 'src/components/iconify';
import { useSnackbar } from 'notistack';
import FormProvider, { RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import { WebServices } from 'src/services/requests';
import { fData, fPhoneNumber } from 'src/utils/format-number';
import RHFIpnutMask from 'src/components/hook-form/rhf-input-mask';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';
import { useRenderData } from '../context/context';
// import RHFSwitch from 'src/components/hook-form/rhf-switch';

// ----------------------------------------------------------------------

export default function AddUser({ setIsCreateUserPage }) {
  const [isNewImageUploaded, setIsNewImageUploaded] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { setResDataAll, fetchAllData } = useRenderData();

  const newPassword = useBoolean();
  const confirmPassword = useBoolean();

  // console.log(resDataAll);

  const [resDataUserTypes, setResDataUserTypes] = useState(null);

  async function fetchAllDataDropdown() {
    const { data } = await WebServices.getAllUserTypes();
    setResDataUserTypes(data?.data);
  }

  useEffect(() => {
    fetchAllDataDropdown();
  }, []);

  // const password = useBoolean();

  const RegisterSchema = Yup.object().shape({
    NameSurname: Yup.string().required('İsim soyisim zorunlu'),
    Email: Yup.string().required('E-Posta zorunlu').email('Mail adres formatı zorunlu'),
    PhoneNumber: Yup.string()
      .required('Telefon Zorunlu')
      .test('no-leading-zero', 'Telefon numarası "0" ile başlayamaz', (value) => {
        if (value) {
          return !value.startsWith('(0');
        }
        return true;
      }),
    UserTypeId: Yup.string().required('Sektördeki Pozisyon zorunlu'),
    //   --

    // Password: Yup.string().required('Yeni Şifre Zorunlu'),
    Password: Yup.string()
      .required('Yeni Parola Zorunlu')
      .min(8, 'Parola en az 8 karakter olmalı')
      .max(24, 'Parola en fazla 24 karakter olmalı')
      .matches(/[a-z]/, 'Parola en az 1 küçük harf içermelidir')
      .matches(/\d/, 'Parola en az 1 rakam içermelidir'),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref('Password'), null], 'Yeni şifre ile eşleşmelidir')
      .required('Onay şifresi zorunlu'),
  });

  const defaultValues = {
    NameSurname: '',
    Email: '',
    PhoneNumber: '',
    UserTypeId: '',
    ImageFile: null,
    // --

    Password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    // control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    const isForm = true;

    delete data.confirmPassword;

    const sendData = {
      ...data,
      ImageFile: isNewImageUploaded ? values.ImageFile : null,
      PhoneNumber: fPhoneNumber(values.PhoneNumber),
    };

    // console.log('sendData ');

    const response = await WebServices.createUser(sendData, isForm);
    // console.log('create category = ', response);
    if (response.success) {
      enqueueSnackbar('İşlem Başarılı');
      reset();
      setIsCreateUserPage(false);
      await new Promise((resolve) => setTimeout(resolve, 200));
      setResDataAll(null);
      fetchAllData();
      // console.log("dönen başarı register : ",response);
    } else {
      enqueueSnackbar('Hatalı İşlem', {
        variant: 'error',
      });
      //  console.log("dönen error register msg : ",response.response);
    }
  });

  const handleDropImage = useCallback(
    (acceptedFiles, setIsNewUpload, valueName) => {
      setIsNewUpload(true);
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue(valueName, newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const values = watch();

  const renderForm = (
    <Grid container spacing={5} pt={0} mt={0}>
      <Grid item xs={12} md={8}>
        <Stack spacing={5} mt={0}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium" variant="subtitle2">
                Ad Soyad
              </Typography>
              {/* <Typography className="text-gray-500 text-sm" variant="caption">
                Mevcut : Erkan Giriş
              </Typography> */}
            </Grid>

            <Grid item xs={12} md={8}>
              <RHFTextField name="NameSurname" placeholder="Adınız Soyadınız" />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium" variant="subtitle2">
                Telefon Numaranız
              </Typography>
              {/* <Typography className="text-gray-500 text-sm" variant="caption">
                Mevcut : 13123213123
              </Typography> */}
            </Grid>

            <Grid item xs={12} md={8}>
              {/* <RHFTextField name="PhoneNumber" /> */}
              <RHFIpnutMask
                name="PhoneNumber"
                mask="(999) 999-9999"
                placeholder="Telefon Numaranız"
                // helperText="Enter your phone number"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium" variant="subtitle2">
                E-Posta Adresiniz
              </Typography>
              {/* <Typography className="text-gray-500 text-sm" variant="caption">
                Mevcut : erkan@gmail.com
              </Typography> */}
            </Grid>

            <Grid item xs={12} md={8}>
              <RHFTextField name="Email" placeholder="E-mail Adresiniz" />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium" variant="subtitle2">
                Sektördeki Pozisyon
              </Typography>
              {/* <Typography className="text-gray-500 text-sm" variant="caption">
                Mevcut : Admin
              </Typography> */}
            </Grid>

            <Grid item xs={12} md={8}>
              {resDataUserTypes ? (
                <RHFSelect
                  name="UserTypeId"
                  placeholder="Sektördeki Pozisyon Seçiniz"
                  InputLabelProps={{ shrink: true }}
                >
                  {/* <MenuItem value="">Seçiniz</MenuItem> */}
                  {resDataUserTypes?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.userType}
                    </MenuItem>
                  ))}
                </RHFSelect>
              ) : (
                <Skeleton height={80} width="100%" />
              )}
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium" variant="subtitle2">
                Yeni Parolanız
              </Typography>
              <Typography className="text-gray-500 text-sm" variant="caption">
                Yeni belirleyeceğiniz parola
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <RHFTextField
                name="Password"
                placeholder="Yeni Parolanız"
                helperText="Parolanız 8 karakterden uzun, harf ve rakamlardan oluşmalıdır"
                type={newPassword.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={newPassword.onToggle} edge="end">
                        <Iconify
                          icon={newPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography className="text-lg font-medium" variant="subtitle2">
                Yeni Parolanız
              </Typography>
              <Typography className="text-gray-500 text-sm" variant="caption">
                Yeni belirleyeceğiniz parola tekrar
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <RHFTextField
                name="confirmPassword"
                placeholder="Yeni Parolanız Tekrar"
                type={confirmPassword.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={confirmPassword.onToggle} edge="end">
                        <Iconify
                          icon={confirmPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={4} />

            <Grid item xs={12} md={8}>
              <LoadingButton
                fullWidth
                sx={{
                  background: '#F8E71C',
                  width: '30%',
                  color: '#000',
                  '&:hover': {
                    background: '#F8E71C', // Keep the same background color on hover
                  },
                }}
                size="large"
                type="submit"
                variant="text"
                loading={isSubmitting}
              >
                Oluştur
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <Stack
          // className="border border-solid border-gray-200 rounded-md w-80 p-10"
          sx={{
            border: '1px solid rgba(145, 158, 171, 0.2)',
            borderRadius: '8px',
            p: 5,
          }}
          alignItems="center"
          spacing={1}
        >
          <RHFUploadAvatar
            isRounded
            sx={{
              width: '150px',
              height: '150px',
            }}
            name="ImageFile"
            maxSize={1048576}
            onDrop={(acceptedFiles) =>
              handleDropImage(acceptedFiles, setIsNewImageUploaded, 'ImageFile')
            }
            helperText={
              <Typography
                variant="caption"
                sx={{
                  mt: 3,
                  mx: 'auto',
                  display: 'block',
                  textAlign: 'center',
                  color: 'text.disabled',
                  maxWidth: '215px',
                }}
              >
                Şu Formatlarda Yükleyiniz: *.PNG, *.JPG, *.JPEG, *.SVG
                <br /> En fazla dosya boyutu: {fData(1048576)}
              </Typography>
            }
          />
          {/* <RHFSwitch
            name="isPublic"
            labelPlacement="start"
            label="Fotoğrafımı Göster"
            sx={{ mt: 5 }}
          /> */}
        </Stack>
      </Grid>
    </Grid>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {/* <Stack my={3}>
        <code>{JSON.stringify(values, null, 2)}</code>
      </Stack> */}
      <Stack>{renderForm}</Stack>
    </FormProvider>
  );
}
