

// ----------------------------------------------------------------------

// eslint-disable-next-line import/no-extraneous-dependencies
import numeral from "numeral";



export function fNumber(number) {
  const formattedNumber = new Intl.NumberFormat('tr-TR').format(number);

  return formattedNumber;
}

export function fCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

export function fPhoneNumber(number) {
  const formattedNumber = number.replace(/[()\s-]/g, '');
  return formattedNumber;
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
