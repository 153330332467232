/* eslint-disable import/no-unresolved */

import axios from 'axios';
import { setSession } from 'src/auth/context/jwt/utils';
import { paths } from 'src/routes/paths';
// import { setSession } from 'src/auth/utils';

const WebServices = {
  get: async (endpoint, params) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASEURL + endpoint}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          XApiKey: `TPJDtRG0cP`,
        },
        params,
      })
      return response;
    } catch (error) {
      console.error(error);

      // console.log("error dönen errror : ",error);

      if (error.response && error.response.status === 401) {
        setSession(null);
        window.location.href = paths.auth.jwt.login;
      } else if (error.response && error.response.status === 403) {
        window.location.href = '/404';
      }

      return {
        data: {
          success: false,
          statusCode: 500,
          message: 'Başarısız',
          detailMessage: 'Başarısız',
          data: undefined,
        },
      };

      // return error;
    }
  },

  post: async (endpoint, params, isForm) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASEURL + endpoint}`, params, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          XApiKey: `TPJDtRG0cP`,
          'Content-Type': isForm ? 'multipart/form-data' : 'application/json',
        },
      });
      return { success: true, response };
    } catch (error) {
      console.error(error);
      return { success: false, response: error?.response?.data?.detailMessage };
    }
  },

  put: async (endpoint, params, isForm) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_BASEURL + endpoint}`, params, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          XApiKey: `TPJDtRG0cP`,
          'Content-Type': isForm ? 'multipart/form-data' : 'application/json',
        },
      });
      return { success: true, response: response.data };
    } catch (error) {
      console.error(error);
      return { success: false, response: error };
    }
  },

  delete: async (endpoint, params) => {
    try {
      const response = await axios.delete(
        `${`${process.env.REACT_APP_API_BASEURL + endpoint}/${params.Id}`}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            XApiKey: `TPJDtRG0cP`,
          },
        }
      );
      // console.log(response);
      return { success: true, response };
    } catch (error) {
      console.error(error);
      return { success: false, response: error };
    }
  },

  // ** Auth
  forgetPasswordEmail: (params, isForm) =>WebServices.put(`User/ForgotPassword`, params, isForm),
  forgetPasswordChange: (params, isForm) =>WebServices.put(`User/ResetPassword`, params, isForm),
  

  // ** categories
  getAllCategories: (params) => WebServices.get(`TheatrePlayCategory/Get`, params),
  getCategoryById: (params) =>
    WebServices.get(`TheatrePlayCategory/GetTheatrePlayCategoryById`, params),
  createCatergory: (params, isForm) =>
    WebServices.post(`TheatrePlayCategory/Create`, params, isForm),
  updateCatergory: (params, isForm) =>
    WebServices.put(`TheatrePlayCategory/Update`, params, isForm),
  deleteCatergory: (params, isForm) => WebServices.delete(`TheatrePlayCategory/Delete`, params),
  getAllUserEstimateDetails: (params) => WebServices.get(`Movie/GetUserEstimateDetails`, params),
  getMovieEstimateDetails: (params) => WebServices.get(`Movie/GetMovieEstimateDetails`, params),

  // ** create user register
  createUser: (params, isForm) => WebServices.post(`User/CreateUser`, params, isForm),
  getAllUserTypes: (params) => WebServices.get(`User/GetUserTypes`, params),
  getUserById: (params) => WebServices.get(`User/GetUserInfo`, params),
  updateUser: (params,isForm) => WebServices.put(`User/UpdateUser`, params,isForm),
  updatePassword: (params,isForm) => WebServices.put(`User/ChangePassword`, params,isForm),

  // ** forecast
  getAllUpcomingMovies: (params) => WebServices.get(`Movie/GetUpcomingMovies`, params),
  createForecast: (params, isForm) =>
    WebServices.post(`Movie/CreateEstimateAudience`, params, isForm),

  // ** leadersBoard
  getAllLeadersBoard: (params) => WebServices.get(`Movie/GetLeaderBoard`, params),
  getAllLeadersBoardDropDown: (params) =>
    WebServices.get(`Movie/GetEndedMoviesForDropdown`, params),

  //  ** Users
  getAllUsers: (params) => WebServices.get(`User/GetUserList`, params),
  updateUserStatusApproved: (params) => WebServices.put(`User/ApproveUsers`, params),
  updateUserStatusBanned: (params) => WebServices.put(`User/BanUsers`, params),

  // ** Complated Films
  getAllCompletedFilms: (params) => WebServices.get(`Movie/GetEndedMovies`, params),
  getAllCompletedFilmsForAdmin: (params) => WebServices.get(`Movie/MovieListForAdmin`, params),
  updateAuidience: (params,isForm) => WebServices.post(`Movie/CreateMovieTotalAudience`, params,isForm),


  // ** userEstimates MyForecats
  getAllMyForecasts: (params) => WebServices.get(`Movie/GetUserEstimates`, params),
  updateMovieEstimate: (params,isForm) => WebServices.post(`Movie/SelectMovieForEstimate`, params,isForm),

  // ** forecast Detail
  getAllMyForecastEventDetail: (params) => WebServices.get(`Movie/GetMovieDetails`, params),
  getAllTopForastMovies: (params) => WebServices.get(`Movie/GetMostEstimatedMovies`, params),
};

export { WebServices };
