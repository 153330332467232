/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import React from 'react';

import { fDate } from 'src/utils/format-time';
import ListItem from './list';

export default function ForecestList({ resDataAll, setSearchKeyword }) {
  return (
    <div className="flex flex-col gap-16 -mt-8  max-md:-m-0 max-md:mt-4 max-md:gap-4">
      {resDataAll?.map((item, index) => {
        return (
          <div key={index}>
            <p className="text-2xl font-bold mb-5 max-md:mb-4 max-md:text-lg">
              {fDate(item.releaseDate)}
            </p>
            <div className="max-lg:flex max-lg:flex-wrap gap-5 max-md:flex-col max-lg:items-start">
              {item.upComingMovies.map((movieItem, movieIndex) => (
                <ListItem
                  key={movieIndex}
                  item={movieItem}
                  date={fDate(item.releaseDate)}
                  setSearchKeyword={setSearchKeyword}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}
