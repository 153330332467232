/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/button-has-type */
// @mui
import Container from '@mui/material/Container';
// components
import { useSettingsContext } from 'src/components/settings';

import LoadingSection from 'src/components/loading-screen/loading-section';
import ErrorSection from 'src/components/error/error';
import { useRenderData } from '../context/context';
import AddEdit from '../add-edit/add-edit';

// ----------------------------------------------------------------------

export default function ProfileWiew() {
  const settings = useSettingsContext();

  const { resDataAll } = useRenderData();

  // console.log("düzenle user : ",resDataAll);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      {resDataAll ? (
        <AddEdit />
      ) : resDataAll === null ? (
        <LoadingSection />
      ) : (
        <ErrorSection
          errorTexts={{
            mainMsg: 'Profil Bilgileriniz Yüklenemedi',
          }}
        />
      )}
    </Container>
  );
}
