/* eslint-disable react/prop-types */
import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
// eslint-disable-next-line import/no-unresolved


export default function EmptyData({ small,text }) {
  
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Avatar
        src="/assets/extra/noData.png"
        alt="Data Bulunamadı"
        // color="primary"
        sx={{
          width: small ? 200 : 0.5,
          height: small ? 200 : 1,
          objectFit: 'cover',
        }}
      >
          Data Bulunamadı
      </Avatar>
      <Typography variant="h6">{text || "Kayıt Bulunamadı"}</Typography>
    </Box>
  );
}
