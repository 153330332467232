/* eslint-disable react/prop-types */
import * as React from 'react';
import Stack from '@mui/material/Stack';
import { Avatar, Typography, useMediaQuery, useTheme } from '@mui/material';

export default function ErrorSection({ errorTexts }) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack p={isMobile ? 2.5 : 5} mt={4} border="1px solid #DFDFDF" borderRadius={1}>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={5} alignItems={isMobile && 'center'}>
        <Avatar
          variant="rounded"
          src="/assets/extra/ErrorFrame.svg"
          alt="resim"
          sx={{ width: isMobile ? '50%' : 184, height: isMobile ? '50%' : 145 }}
        />
        <Stack justifyContent="center" textAlign={isMobile && 'center'}>
          <Typography variant="h4" fontSize={isMobile ? 20 : 32}>
            {errorTexts?.mainMsg || 'Yüklenmede bir hata oluştu'}
          </Typography>
          <Typography
            variant="body1"
            fontSize={isMobile ? 14 : 18}
            sx={{ color: 'rgba(0, 0, 0, 0.50)' }}
          >
            {errorTexts?.detailMsg || 'Bu sorunla ilgileniyoruz. Lütfen daha sonra tekrar deneyin.'}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
